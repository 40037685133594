import React, { useState,useEffect } from "react";
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import KeysData from "./KeysData";

const ErrorHandling = props => {
    const [show, setshow] = useState(true);
    const [tableData, settableData] = useState([]);
    let mapKeys = KeysData;

    const cols = [
        {
          dataField : 'label',
          text:'Label',
          headerStyle : () => { return{width : '250px'}},
          formatter : cell => {
            return(<div>{mapKeys[props.title][cell]}</div>)
          }
        },
        {
          dataField:'error',
          text:'Error Description',
          headerStyle :() => {return{width:'500px'}}
        },
      ];

    //   let options = paginationFactory({
    //     sizePerPage: 5,
    //     paginationSize:3,
    //     hideSizePerPage:true,
    //     totalSize:tableData.length,
    //     hidePageListOnlyOnePage : true,
    //   })


    useEffect(() => {
        let data = props.error ? props.error : null, tableData = [];
        for(let key in data){
          let val = '';
          if(Array.isArray(data[key]))
            data[key].map((errVal,index)=>{
              val += errVal;
              if(index+1 !== data[key].length) val+= ', '
            })
          else val = data[key];
          tableData.push({
            label : key,
            error : val
        })
        }
        settableData(tableData)
    }, []);

    const handleClose =()=>{
        props.closeErrorHandling();
        setshow(false);
    }

    return(
        <Dialog open={show} onClose={handleClose} maxWidth='lg'>
        <DialogTitle>Errors list</DialogTitle>
        <DialogContent>
            <BootstrapTable  bootstrap4  wrapperClasses='table-responsive pad-20' data={tableData} columns={cols} 
                keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found'
                // pagination={options} 
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
}

export default ErrorHandling