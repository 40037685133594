import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, put } from "axios";
import Select from "react-select";
import config from "../../../../config";
import CustomSwitch from "../../../Helper/CustomSwitch";
import Loader from "../../../Helper/Loader";
import { ToastContainer, toast } from "react-toastify";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {
  FaChevronCircleDown,
  FaTrashAlt,
  FaEdit,
  FaCheckCircle,
} from "react-icons/fa";
import Account from "./Account";

const Vendor = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  const [form, setForm] = useState(props.form);
  const [categoryList, setcategoryList] = useState([]);
  const [campaignList, setcampaignList] = useState([]);
  const [concernList, setconcernList] = useState([]);
  const [category, setcategory] = useState(null);
  const [campaign, setcampaign] = useState(null);
  const [concern, setconcern] = useState(null);
  const [aadhar, setAadhar] = useState("");
  const [pan, setPan] = useState("");
  const [enrollForm, setEnrollForm] = useState("");
  const [cheque, setCheque] = useState("");
  const [sign, setSign] = useState("");
  const [showAcc, setshowAcc] = useState(false);
  const [accList, setaccList] = useState([]);
  const [accData, setaccData] = useState({});
  const [editAccIndex, seteditAccIndex] = useState(null);
  const errors = props.errors;

  let isAdminUser = sessionStorage.getItem("permission")
    ? JSON.parse(sessionStorage.getItem("permission"))[0].user_role ===
        "admin" || false
    : false;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const mapLabel = (label) => {
    let val = "Owner";
    switch (label) {
      case "Partnership":
        val = "Firm";
        break;
      case "Private Limited Company":
      case "Public Limited Company":
        val = "Company's";
        break;
    }
    return val;
  };

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    props.save(
      {
        [keyName]: value,
      },
      keyName
    );
  };
  const handleCheckBox = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    props.save(
      {
        [keyName]: value,
      },
      keyName
    );
  };
  const handleFiles = (keyName, e, setFileName) => {
    let fileName = e.target.files.length > 0 ? e.target.files[0].name : "";
    let file = e.target.files.length > 0 ? e.target.files[0] : null;
    setFileName(fileName);
    setForm({
      ...form,
      [keyName]: file,
    });
    props.save(
      {
        [keyName]: file,
      },
      keyName
    );
  };
  const handleSelect = (keyName, setFn, e) => {
    setFn(e);
    setForm({
      ...form,
      [keyName]: (e && e.value) || null,
    });
    props.save(
      {
        [keyName]: (e && e.value) || null,
      },
      keyName
    );
  };
  const clearFile = (keyName, e) => {
    e.target.value = null;
    e.target.files = [];
    setForm({
      ...form,
      [keyName]: null,
    });
    props.save({
      [keyName]: null,
    });
  };

  const handleDialogClose = () => {
    setshowAcc(false);
    setaccData({});
  };

  const addAccDetails = (data) => {
    data.vendor = props.propsData.id;
    if (data.id)
      put(config.service + "/vendor/bank_ac/" + data.id + "/", data, tokenKey)
        .then((res) => {
          let acc = accList;
          acc[editAccIndex] = res.data;
          setaccList(acc);
          seteditAccIndex(null);
          setaccData({});
          setshowAcc(false);
        })
        .catch((err) => {
          setaccData({});
          setshowAcc(false);
          if (err.response.status === 401) window.location.href = "/";
        });
    else
      post(config.service + "/vendor/bank_ac/create/", data, tokenKey)
        .then((res) => {
          let acc = accList;
          acc.push(res.data);
          setaccList(acc);
          setaccData({});
          setshowAcc(false);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          setaccData({});
          setshowAcc(true);
          toast.error("Account is already exists", toastAttr);
        });
  };

  const editAccDetails = (index) => {
    setaccData(accList[index]);
    seteditAccIndex(index);
    setshowAcc(true);
  };

  const deleteAccDetails = (id, index) => {
    axios
      .delete(config.service + "/vendor/bank_ac/" + id + "/", tokenKey)
      .then((resp) => {
        let acc = accList.filter((acc) => acc.id !== id);
        setaccList(acc);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("Deletion Failed", toastAttr);
      });
  };

  useEffect(() => {
    get(config.service + "/masters/vendor_category/", tokenKey)
      .then((res) => {
        let catData = [];
        for (let i in res.data)
          catData.push({ label: res.data[i].category, value: res.data[i].id });
        setcategoryList(catData);
        get(config.service + "/masters/campaign_master/", tokenKey).then(
          (res) => {
            let campData = [];
            for (let i in res.data)
              campData.push({
                label: res.data[i].campaign_name,
                value: res.data[i].id,
              });
            setcampaignList(campData);
            get(config.service + "/masters/type_of_concern/", tokenKey).then(
              (res) => {
                let concData = [];
                for (let i in res.data)
                  concData.push({
                    label: res.data[i].type_name,
                    value: res.data[i].id,
                  });
                setconcernList(concData);
                if (props.propsData && props.propsData.editMode) {
                  get(
                    config.service + "/masters/vendor/" + props.propsData.id,
                    tokenKey
                  )
                    .then((res) => {
                      let data = res.data;
                      let catVal = catData.filter(
                        (temp) => temp.value === data.category_id
                      );
                      let camp = campData.filter(
                        (temp) => temp.value === data.campaign_id
                      );
                      let concern = concData.filter(
                        (temp) => temp.value === data.type_of_concern_id
                      );
                      setcampaign(camp[0]);
                      setconcern(concern[0]);
                      setcategory(catVal[0]);
                      setAadhar(
                        data.owner_aadhar
                          ? data.owner_aadhar.split("/").pop()
                          : ""
                      );
                      setPan(
                        data.owner_PAN ? data.owner_PAN.split("/").pop() : ""
                      );
                      setEnrollForm(
                        data.enrollment_form
                          ? data.enrollment_form.split("/").pop()
                          : ""
                      );
                      setCheque(
                        data.cancelled_cheque
                          ? data.cancelled_cheque.split("/").pop()
                          : ""
                      );
                      setSign(
                        data.authorized_sign
                          ? data.authorized_sign.split("/").pop()
                          : ""
                      );
                      setForm(res.data);
                      get(
                        config.service +
                          "/vendor/bank_ac/" +
                          props.propsData.id,
                        tokenKey
                      ).then((res) => {
                        setaccList(res.data);
                      });
                      props.save(res.data);
                      props.setLoader(false);
                    })
                    .catch((err) => {
                      props.setLoader(false);
                      toast.error("Retrieval Failed", toastAttr);
                    });
                } else props.setLoader(false);
              }
            );
          }
        );
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        props.setLoader(false);
      });
  }, []);

  const verifyAccount = (id) => {
    props.setLoader(true);
    post(
      config.service + "/vendor/banck-ac/verify/",
      { bank_ac_id: id },
      tokenKey
    )
      .then((res) => {
        accList.map((acc) => {
          if (acc.id === id) acc.is_validated = true;
        });
        setaccList(accList);
        props.setLoader(false);
        toast.success("Account verified successfully", toastAttr);
      })
      .catch((err) => {
        toast.error("Account verification failed", toastAttr);
        if (err.response.status === 401) window.location.href = "/";
        props.setLoader(false);
      });
  };

  //   let isApproval = props.propsData ? props.propsData.approval : false;
  let isApproval = false; //remove
  return (
    <>
      <Loader loading={props.loading} overlay={props.overlay}>
        <fieldset className="border p-2 fieldsetCls">
          <legend className="w-auto">Vendor Details</legend>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <Form.Label>Vendor Name</Form.Label>
                </Col>
                <Col md={7}>
                  {/* change */}
                  <Form.Control
                    type="text"
                    name="vendor_name"
                    value={form.vendor_name}
                    onChange={(e) => handleInput("vendor_name", e.target.value)}
                    isInvalid={!!errors.vendor_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.vendor_name}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Address 1</label>
                </Col>
                <Col md={7}>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    cols="50"
                    name="address_1"
                    value={form.address_1}
                    onChange={(e) => handleInput("address_1", e.target.value)}
                    isInvalid={!!errors.address_1 && isApproval}
                  />
                  {isApproval ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.address_1}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Address 2</label>
                </Col>
                <Col md={7}>
                  <textarea
                    rows="4"
                    cols="50"
                    name="address_2"
                    value={form.address_2}
                    onChange={(e) => handleInput("address_2", e.target.value)}
                  >
                    {form.address_2}
                  </textarea>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label>Category</label>
                </Col>
                <Col md={7}>
                  <Select
                    className={`selectBox ${
                      !!errors.category_id && isApproval ? "inValidField" : ""
                    }`}
                    value={category}
                    onChange={(e) =>
                      handleSelect("category_id", setcategory, e)
                    }
                    options={categoryList}
                    isClearable
                  />
                  {isApproval && errors.category_id ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.category_id}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Campaign</label>
                </Col>
                <Col md={7}>
                  <Select
                    className="selectBox"
                    value={campaign}
                    onChange={(e) =>
                      handleSelect("campaign_id", setcampaign, e)
                    }
                    options={campaignList}
                    isClearable
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Type of Concern</label>
                </Col>
                <Col md={7}>
                  <Select
                    className={`selectBox ${
                      !!errors.type_of_concern_id && isApproval
                        ? "inValidField"
                        : ""
                    }`}
                    value={concern}
                    onChange={(e) =>
                      handleSelect("type_of_concern_id", setconcern, e)
                    }
                    options={concernList}
                    isClearable
                    isInvalid={!!errors.type_of_concern_id && isApproval}
                  />
                  {isApproval && errors.type_of_concern_id ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.type_of_concern_id}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
        <fieldset className="border p-2 fieldsetCls">
          <legend className="w-auto">Owner Details</legend>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <Form.Label>Owner Name</Form.Label>
                </Col>
                <Col md={7}>
                  <Form.Control
                    type="text"
                    name="owner_name"
                    value={form.owner_name}
                    onChange={(e) => handleInput("owner_name", e.target.value)}
                    isInvalid={!!errors.owner_name && isApproval}
                  />
                  {isApproval ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.owner_name}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Label>Owner Phone</Form.Label>
                </Col>
                <Col md={7}>
                  <Form.Control
                    type="text"
                    name="owner_phone"
                    value={form.owner_phone}
                    onChange={(e) => handleInput("owner_phone", e.target.value)}
                    isInvalid={!!errors.owner_phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.owner_phone}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label>Owner Email</label>
                </Col>
                <Col md={7}>
                  <input
                    type="email"
                    name="owner_email"
                    value={form.owner_email}
                    onChange={(e) => handleInput("owner_email", e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Label>Contact Email</Form.Label>
                </Col>
                <Col md={7}>
                  <Form.Control
                    type="email"
                    name="contact_email"
                    value={form.contact_email}
                    onChange={(e) =>
                      handleInput("contact_email", e.target.value)
                    }
                    isInvalid={!!errors.contact_email && isApproval}
                  />
                  {isApproval ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.contact_email}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
        {props.propsData && props.propsData.editMode ? (
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Bank Details</legend>
            <Row>
              <Col md={12}>
                <div className="addAcc" onClick={(e) => setshowAcc(true)}>
                  + Add Account Details
                </div>
              </Col>
              {/* <Row> */}
              {accList?.map((acc, index) => {
                return (
                  <Row className="accRow">
                    <Col md={10}>
                      <Accordion className="vendorAccordion">
                        <AccordionSummary
                          className="header"
                          expandIcon={<FaChevronCircleDown />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="title">
                            {acc.nick_name ? acc.nick_name : acc.ac_no}{" "}
                            {acc.is_validated ? (
                              <span style={{ float: "right" }}>
                                Account Verified <FaCheckCircle color="green" />
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className="accData">
                            <Row>
                              <Col md={6}>
                                <Row>
                                  <Col md={4}>
                                    <Form.Label>Account Number</Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      type="text"
                                      disabled
                                      name="ac_no"
                                      value={acc.ac_no}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4}>
                                    <Form.Label>Account Holder</Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      disabled
                                      type="text"
                                      name="ac_holder"
                                      value={acc.ac_holder}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4}>
                                    <Form.Label>IFSC</Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      disabled
                                      type="text"
                                      name="ifsc"
                                      value={acc.ifsc}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6}>
                                <Row>
                                  <Col md={4}>
                                    <label>Branch Address</label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      disabled
                                      as="textarea"
                                      rows="4"
                                      cols="50"
                                      name="branch_address"
                                      value={acc.branch_address}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4}>
                                    <Form.Label>MICR Code</Form.Label>
                                  </Col>
                                  <Col md={7}>
                                    <Form.Control
                                      disabled
                                      type="text"
                                      name="mimr"
                                      value={acc.mimr}
                                    />
                                  </Col>
                                </Row>
                                {!acc.is_validated ? (
                                  <Row>
                                    <Col md={8}></Col>
                                    <Col md={4} xs={12}>
                                      <Button
                                        variant="primary"
                                        onClick={() => verifyAccount(acc.id)}
                                      >
                                        Verify
                                      </Button>
                                    </Col>
                                  </Row>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Col>
                    <Col md={1} style={{ paddingTop: "10px" }}>
                      <FaEdit
                        className="accEdit"
                        onClick={(e) => editAccDetails(index)}
                      />{" "}
                      <FaTrashAlt
                        className="accDelete"
                        onClick={(e) => deleteAccDetails(acc.id, index)}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Row>
          </fieldset>
        ) : (
          ""
        )}
        <fieldset className="border p-2 fieldsetCls">
          <legend className="w-auto">KYC & Documents</legend>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label for="aadhar">
                    {concern
                      ? mapLabel(concern.label) + " Aadhar"
                      : "Owner Aadhar"}
                  </label>
                </Col>
                <Col md={7}>
                  <InputGroup
                    className={`fileButton ${
                      !!errors.owner_aadhar && isApproval ? "inValidType" : ""
                    }`}
                  >
                    <div className="customFile">
                      <Form.Control
                        type="file"
                        title={aadhar}
                        className="fileInput"
                        accept=".img,.jpg,.jpeg,.png,.eps,.ai,.indd,.raw,.psd,.tiff"
                        id="aadharFile"
                        onChange={(e) =>
                          handleFiles("owner_aadhar", e, setAadhar)
                        }
                        isInvalid={!!errors.owner_aadhar && isApproval}
                      />
                      <Form.Label className="fileLabel" htmlFor="aadharFile">
                        {aadhar}
                      </Form.Label>
                    </div>
                  </InputGroup>
                  {isApproval && errors.owner_aadhar ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.owner_aadhar}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label for="pan">
                    {concern ? mapLabel(concern.label) + " PAN" : "Owner PAN"}
                  </label>
                </Col>
                <Col md={7}>
                  <InputGroup
                    className={`fileButton ${
                      !!errors.owner_PAN && isApproval ? "inValidType" : ""
                    }`}
                  >
                    <div className="customFile">
                      <Form.Control
                        type="file"
                        title={pan}
                        className="fileInput"
                        accept=".img,.jpg,.jpeg,.png,.eps,.ai,.indd,.raw,.psd,.tiff"
                        id="panFile"
                        onChange={(e) => handleFiles("owner_PAN", e, setPan)}
                        isInvalid={!!errors.owner_PAN && isApproval}
                      />
                      <Form.Label className="fileLabel" htmlFor="panFile">
                        {pan}
                      </Form.Label>
                    </div>
                  </InputGroup>
                  {isApproval && errors.owner_PAN ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.owner_PAN}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label for="enrollForm">Agreement Form</label>
                </Col>
                <Col md={7}>
                  <InputGroup
                    className={`fileButton ${
                      !!errors.enrollment_form && isApproval
                        ? "inValidType"
                        : ""
                    }`}
                  >
                    <div className="customFile">
                      <Form.Control
                        type="file"
                        title={enrollForm}
                        className="fileInput"
                        accept=".img,.jpg,.jpeg,.png,.eps,.ai,.indd,.raw,.psd,.tiff"
                        id="enrollForm"
                        onChange={(e) =>
                          handleFiles("enrollment_form", e, setEnrollForm)
                        }
                        isInvalid={!!errors.enrollment_form && isApproval}
                      />
                      <Form.Label className="fileLabel" htmlFor="enrollForm">
                        {enrollForm}
                      </Form.Label>
                    </div>
                  </InputGroup>
                  {isApproval && errors.enrollment_form ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.enrollment_form}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label for="cheque">Cancelled Cheque</label>
                </Col>
                <Col md={7}>
                  <InputGroup
                    className={`fileButton ${
                      !!errors.cancelled_cheque && isApproval
                        ? "inValidType"
                        : ""
                    }`}
                  >
                    <div className="customFile">
                      <Form.Control
                        type="file"
                        title={cheque}
                        className="fileInput"
                        accept=".img,.jpg,.jpeg,.png,.eps,.ai,.indd,.raw,.psd,.tiff"
                        id="cheque"
                        onChange={(e) =>
                          handleFiles("cancelled_cheque", e, setCheque)
                        }
                        isInvalid={!!errors.cancelled_cheque && isApproval}
                      />
                      <Form.Label className="fileLabel" htmlFor="cheque">
                        {cheque}
                      </Form.Label>
                    </div>
                  </InputGroup>
                  {isApproval && errors.cancelled_cheque ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.cancelled_cheque}
                    </Form.Control.Feedback>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label for="sign">Authorized Sign</label>
                </Col>
                <Col md={7}>
                  <InputGroup className="fileButton">
                    <div className="customFile">
                      <Form.Control
                        type="file"
                        title={sign}
                        className="fileInput"
                        accept=".img,.jpg,.jpeg,.png,.eps,.ai,.indd,.raw,.psd,.tiff"
                        id="sign"
                        onChange={(e) =>
                          handleFiles("authorized_sign", e, setSign)
                        }
                      />
                      <Form.Label className="fileLabel" htmlFor="sign">
                        {sign}
                      </Form.Label>
                    </div>
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
        <fieldset className="border p-2 fieldsetCls">
          <legend className="w-auto">Confirmation</legend>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <label>Seen Owner</label>
                </Col>
                <Col md={7}>
                  <CustomSwitch
                    handleChange={handleCheckBox}
                    name="demo_seen_owner"
                    value={form.demo_seen_owner}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Seen Manager</label>
                </Col>
                <Col md={7}>
                  <CustomSwitch
                    handleChange={handleCheckBox}
                    name="demo_seen_manager"
                    value={form.demo_seen_manager}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label>Confirm Account</label>
                </Col>
                <Col md={7}>
                  <CustomSwitch
                    handleChange={handleCheckBox}
                    name="account_confirmation"
                    value={form.account_confirmation}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              {isAdminUser ? (
                <React.Fragment>
                  <Row>
                    <Col md={4}>
                      <label>KYC Done</label>
                    </Col>
                    <Col md={7}>
                      <CustomSwitch
                        handleChange={handleCheckBox}
                        name="is_kyc_pending"
                        value={form.is_kyc_pending}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label>Images Uploaded</label>
                    </Col>
                    <Col md={7}>
                      <CustomSwitch
                        handleChange={handleCheckBox}
                        name="is_images_pending"
                        value={form.is_images_pending}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </fieldset>
      </Loader>
      {showAcc ? (
        <Account
          accData={accData}
          handleClose={(e) => handleDialogClose()}
          addAccDetails={addAccDetails}
        />
      ) : (
        ""
      )}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Vendor;
