import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FaPercent } from "react-icons/fa";
import { uniqBy } from "lodash";
import { Col, Row, Button, Spinner } from "reactstrap";
import { CustomClientSideGrid } from "../../../../shared/ClientSidePaginationGrid";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModel from "../../../../shared/ConfirmationModel";
import config from "../../../../config";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddModel({ ChangedData, close, fetchData }) {
  const [value, setValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openNested, setOpenNested] = useState(false);
  const [confirm, setConfirm] = useState(false);
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  const data = uniqBy(ChangedData);

  //     useEffect(()=>{
  //       let arr=[]
  //       rowData.map(function(x){
  //         let result=data.filter(a1=> a1.id===x.id);
  //         if(result.length>0) {
  // console.log('x?.has_discount ',x?.has_discount ,result[0]?.has_discount,result[0]?.discount_percent,discountValue)
  // console.log(result[0]?.has_discount!==x?.has_discount ,result[0]?.discount_percent !== parseInt(discountValue))
  //           if(result[0]?.has_discount !==x?.has_discount || result[0]?.discount_percent !==parseInt(discountValue) ){
  //             arr.push(result[0])
  //           }
  //         }
  //         return x })
  //         console.log('arr',arr)

  //     },[discountValue])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const columnDefs = [
    {
      headerName: "ID",
      field: "id",

      filter: true,
      width: "100px",

      resizable: true,
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
        defaultOption: "equals",
      },
    },
    {
      headerName: "Venue Name",
      field: "mandap_name",
      filter: true,
      resizable: true,
      width: 300,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "City",
      field: "city_name",
      filter: true,
      resizable: true,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "Venue Type",
      field: "mandap_type_name",
      filter: true,
      width: 180,
      resizable: true,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
    {
      headerName: "Discount Status",
      field: "has_discount",
      resizable: true,
      filter: true,
      width: 180,
      cellRenderer: (params) => {
        return params?.value === true ? "Available" : "UnAvailable";
      },
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },

    {
      headerName: "Discount(%)",
      field: "discount_percent",
      resizable: true,
      filter: true,
      width: 150,
      filterParams: {
        filterOptions: ["equals"],
        suppressAndOrCondition: true,
        defaultOption: "equals",
      },
    },
    {
      headerName: "Address",
      field: "address_1",

      resizable: true,
      filter: true,
      width: 400,
      filterParams: {
        filterOptions: ["contains"],
        suppressAndOrCondition: true,
        defaultOption: "contains",
      },
    },
  ];
  useEffect(() => {
    if (
      (discountValue !== null ||
        !(parseInt(discountValue) <= 0) ||
        !(parseInt(discountValue) > 100)) &&
      confirm === true
    ) {
      const FinalData = data.map((i) => {
        i.has_discount = true;
        i.discount_percent = discountValue || 0;
        return i;
      });
      setLoading(true);
      axios
        .put(
          `${config.service}/masters/mandap_discount_bulk_upload/`,
          FinalData,
          { headers: tokenKey }
        )
        .then(function (response) {
          toast.success("Discount updated Sucessfully");
          setLoading(false);
          fetchData();
          close();
        })
        .catch(function (error) {
          setLoading(false);
          toast.error("Discount cant be updated");
        });
    }
  }, [confirm]);

  const handlePost = () => {
    setOpenNested(true);
  };

  const handleCloseModal = () => {
    setOpenNested(false);
  };
  const handleConfirmation = () => {
    setConfirm(true);
    handleCloseModal();
  };

  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <Col xs={10}>
          <TextField
            id="outlined-basic"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FaPercent />
                </InputAdornment>
              ),
            }}
            error={
              parseInt(discountValue) <= 0 || parseInt(discountValue) > 100
            }
            size="small"
            type="number"
            label="Add Discount Value"
            variant="outlined"
            helperText={
              parseInt(discountValue) <= 0 || parseInt(discountValue) > 100
                ? "Discount can not be less than 0 or greater than 100"
                : null
            }
            onChange={(event) => {
              setDiscountValue(event.target.value);
            }}
          />
        </Col>
        <Col xs={2}>
          <Button
            color="primary"
            onClick={() => handlePost()}
            disabled={
              discountValue === null ||
              parseInt(discountValue) <= 0 ||
              parseInt(discountValue) > 100
            }
          >
            Create
          </Button>
          <Button onClick={() => close()}>Cancel</Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        {" "}
        <Col>Total Number of Records to be Changed: {data?.length}</Col>
      </Row>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Changed List" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100px",
              }}
            >
              <Spinner color="primary"></Spinner>{" "}
            </div>
          ) : (
            <CustomClientSideGrid
              rowData={ChangedData}
              columnDefs={columnDefs}
              tableHeight={"400px"}
            />
          )}
        </CustomTabPanel>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ConfirmationModel
        operation={"Create"}
        val={openNested}
        handleConfirmation={handleConfirmation}
        modelName={"Discounts"}
        onclose={handleCloseModal}
      />
    </>
  );
}
AddModel.propTypes = {
  ChangedData: PropTypes.any.isRequired,
  close: PropTypes.any.isRequired,
};
