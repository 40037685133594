import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
// import Search from '../../../Helper/CustomSearch'
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import Loader from "../../../Helper/Loader";

const PaymentResponses = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let breadcrumbData = [
    {
      title: "Payments",
      href: "/payment",
    },
    {
      title: "Response",
      href: "#",
    },
  ];

  const [tableData, settableData] = useState([]);
  const [loading, setloading] = useState(true);
  const [overlay, setOverlay] = useState(false);

  const [page, setpage] = useState({
    page: 1,
    count: 1,
    total: 0,
    next: null,
    previous: null,
  });

  const cols = [
    {
      dataField: "encodedResponse",
      text: "Encoded Response",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: (cell, row) => {
        let val = row.encodedResponse ? row.encodedResponse : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "15px" }}>{val}</div>
        );
      },
    },
    {
      dataField: "decodedResponse",
      text: "Decoded Response",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: (cell, row) => {
        let val = row.decodedResponse ? row.decodedResponse : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "15px" }}>{val}</div>
        );
      },
    },
    {
      dataField: "encodedBodyResponse",
      text: "Encoded Body Response",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: (cell, row) => {
        let val = row.encodedBodyResponse ? row.encodedBodyResponse : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "15px" }}>{val}</div>
        );
      },
    },
    {
      dataField: "created_on",
      text: "Created Date",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: (cell, row) => {
        let val = row.created_on
          ? row.created_on.replace("T", " ").replace("Z", "").split(".")[0]
          : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "15px" }}>
            {val.slice(0, 19)}
          </div>
        );
      },
    },
    //   {
    //     dataField : 'status',
    //     text:"Status",
    //     headerStyle : () => { return{width : '120px'}},
    //     formatter:(cell,row)=>{
    //        let val = statusMap[cell] ? statusMap[cell] : cell
    //         return(<div>{val}</div>);
    //       }
    //   },
  ];

  const setLoader = (val) => {
    setloading(val);
    setOverlay(val);
  };

  const renderForm = (row) => {
    if (row)
      history.push({
        pathname: "/payment/logForm",
        state: {
          id: row.id,
          // vendor_id : propsState.id,
          editMode: true,
        },
      });
    else
      history.push({
        pathname: "/payment/logForm",
        state: {
          // vendor_id : propsState.id,
        },
      });
  };

  //   const renderOnSearch = searchTerm => {
  //     setLoader(true);
  //     get(config.service+'/paymentlog/?&search='+searchTerm,tokenKey).then(res=>{
  //       settableData(res.data);
  //       setLoader(false);
  //     }).catch(err=>{
  //         setLoader(false);
  //       if(err.response.status === 401) window.location.href = '/'
  //       toast.error('Search Failed', toastAttr);
  //     })
  //   }

  const rowEvents = {
    onClick: (e, row) => {
      history.push({
        pathname: "/paymentResponseForm",
        state: {
          id: row.id,
          editMode: true,
        },
      });
    },
  };

  const manipulateData = (res, pageNum) => {
    let count = Math.ceil(res.data.count / 20);
    setpage({
      page: pageNum || page.page,
      count: count,
      total: res.data.count,
      next: res.data.next,
      previous: res.data.previous,
    });
    settableData(res.data.results);
    setLoader(false);
  };

  const handleTablePagination = (type, { page }) => {
    if (type === "pagination") {
      setLoader(true);
      let url =
        config.service + "/masters/payment_response_lists/?&page=" + page;
      // if(!empty(searchTerm)) url += '&search='+searchTerm;
      get(url, tokenKey)
        .then((res) => {
          manipulateData(res, page);
          window.scrollTo({
            top: 0,
            behavior: "instant",
          });
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    get(config.service + "/masters/payment_response_lists/", tokenKey)
      .then((res) => {
        // settableData(res.data.results);
        // setLoader(false);
        manipulateData(res, 1);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.status === 401) window.location.href = "/";
      });
  }, []);

  let options = paginationFactory({
    page: page.page,
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    totalSize: page.total,
  });

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}></Col>
                <Col md={4}>
                  {/* <Search callbackFn={e=>renderOnSearch(e)} /> */}
                </Col>
              </Row>
              <Row className="vendorList">
                <Loader loading={loading} overlay={overlay}>
                  <BootstrapTable
                    remote
                    bootstrap4
                    wrapperClasses="table-responsive  pad-20"
                    data={tableData}
                    columns={cols}
                    keyField="id"
                    containerStyle={{ width: "100%" }}
                    hover
                    noDataIndication="No data found"
                    rowEvents={rowEvents}
                    pagination={options}
                    onTableChange={handleTablePagination}
                  />
                </Loader>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default PaymentResponses;
