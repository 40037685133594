import React, { useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";


const Breadcrumbs = (props) => {
  let history = useHistory();
  let length = props.data.length;
  const redirect = (path, state) => {
    if (state)
      history.push({
        pathname: path,
        state: state,
      });
    else history.push(path);
  };
  return (
    <Breadcrumb style={{fontWeight:700}}>
      {props.data &&
        props.data.map((obj, index) => {
          if (length === 1)
            return (
              <Breadcrumb.Item className="singleNav">
                {obj.title}
              </Breadcrumb.Item>
            );
          else if (index === length - 1)
            return <Breadcrumb.Item active>{obj.title}</Breadcrumb.Item>;
          else
            return (
              <Breadcrumb.Item
                onClick={(e) => {
                  redirect(obj.href, obj.state);
                }}
              >
                {obj.title}
              </Breadcrumb.Item>
            );
        })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
