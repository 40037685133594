import React,{useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import {FiEdit2,FiTrash2} from 'react-icons/fi'
import axios,{get} from 'axios';
import config from '../../../../config'
import { ToastContainer, toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import BlogForm from './blogForm';
import { Button, Form } from 'react-bootstrap';


const Blog = (props) => {

    let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};

    const [showModal,setshowModal] = useState(false);
    const [rowData,setrowData] = useState(null);
    const [tableData,settableData] = useState(props.data || [])
    const [check,setCheck] = useState(1);
    
    let toastAttr = {
        theme: "colored",
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        };

    useEffect(() => {
        
    }, []);
    
  const rowEvents = {
    onClick : (e,row) =>{
      setshowModal(true);
      setrowData(row);
    }
  }

  const showContent = row => {
      setshowModal(true);
      setrowData(row);
  }

  const cols = [
    {
        dataField : 'heading',
        text:'Blog Heading',
        headerStyle : () => { return{width : '300px'}}
      },{
        dataField : 'mandap_name',
        text:'Mandapam Name',
        headerStyle : () => { return{width : '400px'}}
      },
      {
        dataField : 'id',
        text:'Actions',
        headerStyle : () => { return{width : '60px'}},
        events : {
          OnClick : e=>{return e.stopPropagation(); }
        },
        formatter:(cell,row)=>{
          return(
          <div className='tableActions'>
            <React.Fragment>
              {/* <span style={{marginRight:'10px',textDecoration:'underline'}} onClick={()=>showContent(row)}>View Content</span> */}
           <FiEdit2 onClick={()=>showContent(row)} />
            <FiTrash2 className='themeColor actionIcon' onClick={e => deleteRow(row) } />
            </React.Fragment>
          </div>)
        }
      }
  ];

  
  const deleteRow = row => {
    props.setLoader(true)
    axios.delete(config.service+'/masters/blog/'+row.id,tokenKey).then(resp=>{
      toast.success('Deleted successfully', toastAttr);
      props.triggerTableData()
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = "/"
      toast.error('Deletion Failed', toastAttr);
      props.setLoader(false)
    })
  }

  let options = paginationFactory({
    sizePerPage: 10,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:tableData.length
  })

  const handleModalClose = (isCancel) => {
    props.setLoader(true)
    if(!isCancel){
      setshowModal(false);
      setrowData(null);
      props.triggerTableData()
    }
    else{
      setshowModal(false);
    setrowData(null);
    props.setLoader(false)
    } 
  }


    return(
        <>
        <Row>
          <Col md={12} className=''>
            <Button variant='primary' onClick={()=>showContent(null)}> + Add Blog</Button> 
          </Col>
        </Row>
        <Row className='vendorList'>
            <BootstrapTable  bootstrap4  wrapperClasses='table-responsive pad-20' data={tableData} columns={cols} 
                keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
                pagination={options} />
            <Col md={6}></Col>
            <span style={{display:'none'}}>{check}</span>
        </Row>
        {showModal ? <BlogForm data={rowData} mandapList={props.mandapList} handleClose={handleModalClose} /> :''}
        <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        </>
    )
}

export default Blog;