import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import config from "../../../../config";
import Loader from "../../../Helper/Loader";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";

const MandapAvailabilityForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [mandapName, setMandapName] = useState([]);
  const [mandapAvailability, setMandapAvailability] = useState([]);
  const [data, setData] = useState([]);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnclick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [tab, setTab] = useState({
    details: true,
  });

  const CheckavailabilityForm = () => {
    let data = {
      mandap_id: form.mandap_id,
      from_date: moment(form.booking_from).format("YYYY-MM-DD hh:mm:ss"),
      to_date: moment(form.booking_to).format("YYYY-MM-DD hh:mm:ss"),
    };

    axios({
      url: config.service + "/masters/mandap/check/availability/",
      method: "POST",
      headers: tokenKey,
      data: data,
    })
      .then((res) => {
        toast.success("Mandap is Availability", toastAttr);
      })
      .catch((err) => {});
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };
  useEffect(() => {
    get(config.service + "/masters/base/vendor-mandap/", { headers: tokenKey })
      .then((res) => {
        setMandapName([...res?.data]);
        let mandap = [];
        res.data.map((obj) => {
          mandap.push({ value: obj.id, label: obj.mandap_name });
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  const onChangeHandler = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("id");
    setForm({ ...form, mandap_id: option });
  };
  const handleDate = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text themeColor pad-l-10">
                  MandapAvailability
                </div>
              </div>
              <Row className="formBtnsDiv">
                <Col md={12}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      CheckavailabilityForm();
                    }}
                  >
                    CheckAvailability
                  </Button>
                </Col>
              </Row>
              <Row className="formBtnsDiv">
                <Col md={12} style={{ margin: "5px 0" }}></Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              <Row>
                                <Col md={6}>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label>Mandap Names</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Form.Control
                                        requried
                                        as="select"
                                        name="mandap_name"
                                        onChange={(e) => onChangeHandler(e)}
                                      >
                                        {mandapName?.map((item) => (
                                          <option
                                            id={item?.id}
                                            value={item?.id}
                                          >
                                            {item.mandap_name}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label>Booking From</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <DateTimePicker
                                        format={"dd-MM-yyyy hh:mm:ss a"}
                                        required
                                        minDate={new Date()}
                                        name="booking_from"
                                        value={form.booking_from}
                                        onChange={(val) => {
                                          handleDate("booking_from", val);
                                        }}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.booking_from}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label>Bookin To</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <DateTimePicker
                                        format={"dd-MM-yyyy hh:mm:ss a"}
                                        required
                                        minDate={form.booking_from}
                                        name="booking_to"
                                        value={form.booking_to}
                                        onChange={(val) => {
                                          handleDate("booking_to", val);
                                        }}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.booking_to}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default MandapAvailabilityForm;
