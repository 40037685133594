import React, { useEffect, useState } from "react";
import config from "../../../../config";
import { Button } from "react-bootstrap";
import axios from "axios";

import Spinner from "react-bootstrap/Spinner";
import ModalforVideoPlayer from "../../../Components/ModalforVideoPlayer";

import { v4 as uuidv4 } from "uuid";
// import JsFileDownloader from 'js-file-downloader';
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../../../Helper/Loader";
import ConfirmationModel from "../../../../shared/ConfirmationModel";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const VideoUpload = (props) => {
  // let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  const [allVideo, setAllVideo] = useState([]);
  const [check, setcheck] = useState(0);
  const [loader, setLoader] = useState({ loading: true, overlay: false });

  const [inputs, setInputs] = useState([]);
  const [newInput, setNewInput] = useState("");
  const [showAdd, setShowAdd] = useState(false);

  const [selectedVideo, setselectedVideo] = useState(null);
  const [isplayerClicked, setplayerClicked] = useState(false);
  const [id, setId] = useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [isremovebtnClicked, setremovebtnClicked] = useState(false);
  const [ispageLoading, setpageLoading] = useState(true);
  const [isaddvideoLinkCliked, setaddVideoLink] = useState(false);
  const [isreloadrequire, setreloadrequired] = useState(false);
  const [openNested, setOpenNested] = useState(false);
  const [openNestedDelete, setOpenNestedDelete] = useState(false);
  const [iswrongUrl, setwrongUrl] = useState(false);

  const [confirm, setConfirm] = useState(false);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const setLoading = (val) => {
    setLoader({ loading: val, overlay: val });
  };

  const handleInputChange = (event) => {
    setNewInput(event.target.value);
    setShowAdd(!!event.target.value);
  };

  const handleAddInput = () => {
    if (newInput) {
      const newVideoLink = { id: uuidv4(), youtube_links: newInput };
      const isDuplicate = inputs.some((obj) => {
        const Obj1 = obj.youtube_links.split("?si");
        const Obj2 = newVideoLink.youtube_links.split("?si");
        return Obj1[0] === Obj2[0];
      });

      const isWrongUrl = newInput.startsWith("<iframe");

      if (!isDuplicate && !isWrongUrl) {
        setInputs([...inputs, newVideoLink]);
        setNewInput("");
        setShowAdd(false);
        setwrongUrl(false);
      } else {
        if (isWrongUrl) {
          toast.error("Please Post the src Link not iframe link");
          setwrongUrl(true);
        } else {
          toast.error("You are trying to add Duplicate Link");
        }
        setNewInput("");
      }
    }
  };

  const handlekeyDown = (event) => {
    if (event.key === "Enter") {
      if (newInput) {
        const newVideoLink = { id: uuidv4(), youtube_links: newInput };
        const isDuplicate = inputs.some(
          (obj) => obj.youtube_links === newVideoLink.youtube_links
        );
        if (!isDuplicate) {
          setInputs([newVideoLink, ...inputs]);
          setNewInput("");
          setShowAdd(false);
        } else {
          setNewInput("");
        }
      }
    }
  };

  const handleRemoveInput = (removeId) => {
    const updatedInputs = [...inputs];
    let filteredInputs = updatedInputs?.filter(
      (inputs) => inputs?.id !== removeId?.id
    );
    setInputs(filteredInputs);
    setremovebtnClicked(true);
  };

  const handlePlayVideo = (videoLink) => {
    setselectedVideo(videoLink);
    setplayerClicked(!isplayerClicked);
  };

  const handleCloseVideo = () => {
    setplayerClicked(!isplayerClicked);
  };

  const handleClose = () => {
    setOpenBackdrop(false);
  };

  const handlePostVideoLinks = () => {
    // setpostbtnClicked(true);
    setOpenNested(true);
  };

  const handleDeleteVideo = () => {
    setOpenNestedDelete(true);
  };
  const handleConfirmationDelete = () => {
    handleCloseModal();
    setOpenBackdrop(true);
    axios
      .delete(config.service + "/masters/mandap-video/" + props.mandapId, {
        headers: {
          Authorization: "Token " + sessionStorage.getItem("tokenKey"),
        },
        data: {
          video_id: id,
        },
      })
      .then((res) => {
        toast.success("Video Deleted successfully", toastAttr);
        setInputs([]);

        setAllVideo([]);
        setOpenBackdrop(false);
      })
      .catch((err) => {
        toast.error("Deleting videos failed", toastAttr);
        setOpenBackdrop(false);
      });
  };

  const getVideoData = async () => {
    const axiosConfig = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("tokenKey")}`,
      },
    };
    try {
      const url =
        config.service + "/masters/mandap-video/" + props.mandapId + "/";

      const response = await axios.get(url, axiosConfig);
      let youtube = response?.data;
      if (youtube?.length > 0) {
        const val = youtube?.filter((i) => i.youtube_links !== null);
        setId(val[0]?.id);
        if (val?.length > 0) {
          const parseVideo = JSON.parse(val?.[0]?.youtube_links)
            ? JSON.parse(val?.[0]?.youtube_links)
            : [];

          setAllVideo(parseVideo);

          setInputs(parseVideo);
        }
      }
      setLoading(false);

      setpageLoading(false);
    } catch (error) {
      console.error("There was an error!", error);
      setpageLoading(false);
    }
  };

  useEffect(() => {
    getVideoData();
  }, [isreloadrequire]);
  const handleCloseModal = () => {
    setOpenNested(false);
    setOpenNestedDelete(false);
  };
  const handleConfirmation = () => {
    setConfirm(true);
    handleCloseModal();
    setOpenBackdrop(true);

    let videoData = new FormData();

    videoData.append("youtube_links", JSON.stringify(inputs));

    const url =
      config.service + "/masters/mandap-video/" + props.mandapId + "/";

    if (allVideo?.length === 0) {
      fetch(url, {
        method: "POST",
        body: videoData,
        headers: {
          Authorization: `Token ${sessionStorage.getItem("tokenKey")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          toast.success("Video uploaded successfully", toastAttr);
          getVideoData();
          setOpenBackdrop(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          toast.error("Uploading video failed", toastAttr);

          setreloadrequired(!true);
          setOpenBackdrop(false);
        });
    } else {
      videoData.append("id", id);
      setOpenBackdrop(true);
      fetch(url, {
        method: "PUT",
        body: videoData,
        headers: {
          Authorization: `Token ${sessionStorage.getItem("tokenKey")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          toast.success("Video uploaded successfully", toastAttr);

          setreloadrequired(!true);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          toast.error("Uploading video failed", toastAttr);

          setreloadrequired(!true);
          setOpenBackdrop(false);
        });
    }
  };

  return (
    <>
      <Loader loading={loader.loading} overlay={loader.overlay}>
        <aside style={thumbsContainer} className="">
          <section className="container ">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="d-flex justify-content-start align-center">
                <Button
                  variant="primary"
                  onClick={() => setaddVideoLink(!isaddvideoLinkCliked)}
                >
                  + Add Video Links
                </Button>
                <div>
                  {inputs?.length > 0 && (
                    <Button onClick={() => handleDeleteVideo()}>
                      Delete All Videos
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {isaddvideoLinkCliked && (
              <div style={{ margin: "10px 0px" }}>
                <input
                  type="text"
                  style={{ width: "30%", marginRight: "10px" }}
                  value={newInput}
                  onChange={handleInputChange}
                  onKeyDown={handlekeyDown}
                  placeholder="Enter Youtube Embed link"
                  required
                />

                {showAdd && (
                  <Button variant="success" onClick={handleAddInput}>
                    Add
                  </Button>
                )}
                {iswrongUrl && (
                  <p style={{ color: "red" }}>
                    * Please Provide the right url from iframe src
                  </p>
                )}
              </div>
            )}

            {ispageLoading ? (
              <div className="d-flex flex-row justify-content-start">
                <Spinner
                  animation="grow"
                  variant="success"
                  size="sm"
                  className="mr-1"
                />
                <Spinner
                  animation="grow"
                  variant="danger"
                  size="sm"
                  className="mr-1"
                />
                <Spinner
                  animation="grow"
                  variant="warning"
                  size="sm"
                  className="mr-1"
                />
              </div>
            ) : // <Button onClick={() => handlePostVideoLinks()}>Post</Button>
            inputs.length > 0 ? (
              inputs.map((videoLink, index) => (
                <div key={index}>
                  <input
                    type="text"
                    value={videoLink?.youtube_links}
                    readOnly
                    style={{ width: "30%", marginRight: "10px" }}
                  />

                  <Button
                    onClick={() => handleRemoveInput(videoLink)}
                    variant="danger"
                  >
                    Remove
                  </Button>

                  <Button
                    onClick={() => handlePlayVideo(videoLink?.youtube_links)}
                    variant="info"
                  >
                    Preview
                  </Button>
                </div>
              ))
            ) : (
              <p>No data to show</p>
            )}
            <div className="d-flex flex-row justify-content-start align-items-start">
              <div className="mr-2 mt-1">
                {" "}
                <Button onClick={() => handlePostVideoLinks()}>
                  {allVideo?.length > 0 ? "Update" : "Post"}
                </Button>
              </div>
            </div>
          </section>

          <span style={{ display: "none" }}>{check}</span>
        </aside>
        <ModalforVideoPlayer
          isplayerClicked={isplayerClicked}
          selectedVideo={selectedVideo}
          handleCloseVideo={handleCloseVideo}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Loader>
      <ConfirmationModel
        operation={"Add"}
        val={openNested}
        handleConfirmation={handleConfirmation}
        modelName={"Video"}
        onclose={handleCloseModal}
      />
      <ConfirmationModel
        operation={"Delete"}
        val={openNestedDelete}
        handleConfirmation={handleConfirmationDelete}
        modelName={"Video"}
        onclose={handleCloseModal}
      />
    </>
  );
};

export default VideoUpload;
