import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config';
import Loader from '../../../Helper/Loader'
import empty from 'is-empty'
import Select from 'react-select'
import Breadcrumbs from '../../../Helper/Breadcrumbs'


const PaymentResponseForm = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  let breadcrumbData = [
    {
      title : 'Payment',
      href : '/payment'
    },{
      title : 'Response',
      href : '/paymentResponse'
    },{
      title : 'View',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
    // let statusMap = {
    //     'payment_requested' : 'Payment Requested',
    //     'order_requested' : 'Order Requested',
    //     'payment_success' : 'Payment Success',
    //     'order_failed' : 'Order Failed',
    //     'payment_failed' : 'Payment Failed'
    // }
  const [tab, setTab] = useState({
      details:true,
  });
    const [tabData, setTabData] = useState([
        {
            name:'Payment Log Details',
            id:'details',
            show:true
        }
    ]);

    // const handleInput = (keyName,value) => {
    //     setForm({
    //         ...form,
    //         [keyName] : value
    //     })
    //     if (keyName && !!errors[keyName]) setErrors({
    //         ...errors,
    //         [keyName]: null
    //       })
    // }

    // const handleSelect = (keyName,setFn,e) =>{
    //     setFn(e);
    //     setForm({
    //         ...form,
    //         [keyName] : (e && e.value) || null
    //     })
    //     if (keyName && !!errors[keyName]) setErrors({
    //         ...errors,
    //         [keyName]: null
    //       })
    // }


  const findErrors = () => {
    const { name } = form
    const newErrors = {}
    if ( empty(name) ) newErrors.name = 'This is a required field';

    return newErrors;
   }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }


  useEffect(() => {
    if(propsState && propsState.editMode)
        get(config.service+'/masters/payment_response/'+propsState.id,{headers:tokenKey}).then(res=>{
            setForm(res.data[0]);
            setLoader(false);
        }).catch(err=>{
          setLoader(false);
        })
        setLoader(false);
  }, []);


  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
        <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
                  </div>
        <Row className='formDiv'>
            <Col md={12}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                            {tabData.map((tab,index)=>{
                                let disCls = tab.disable;
                                return tab.show ? (
                                    <a className={`nav-item nav-link active`} 
                                    id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'>
                                        {tab.name}
                                    </a>
                                ) : '';
                            })}
                        </div>
                    </div>
                </nav>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                    <Loader loading={loading} overlay={overlay}>
                        {tab.details ? 
                        <>
                            <Row>
                                <Col md={4}>
                                    <Form.Label>Created Date</Form.Label>
                                </Col>
                                <Col md={7}>
                                <Form.Control disabled type='text'  value={form.created_on && form.created_on.replace('T',' ').replace('Z','').split('.')[0]} />
                                </Col>
                            </Row>
                            
                            <Row>
                            <Col md={4}>
                                    <Form.Label>Encoded Body Response</Form.Label>
                                </Col>
                                <Col md={7}>
                                <Form.Control style={{height:'100%'}} as='textarea' rows={6} disabled  value={form.encodedBodyResponse} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Label>Encoded Response</Form.Label>
                                </Col>
                                <Col md={7}>
                                <Form.Control style={{height:'100%'}} as='textarea' rows={6} disabled  value={form.encodedResponse} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Label>Decoded Response</Form.Label>
                                </Col>
                                <Col md={7}>
                                <Form.Control style={{height:'100%'}} as='textarea' rows={6} disabled  value={form.decodedResponse} />
                                </Col>
                            </Row>
                            </>
                        : ''}
                    </Loader>
                    </Form>
                </div>
            </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default PaymentResponseForm;
