import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody} from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {FiEdit2,FiTrash2} from 'react-icons/fi'
import Search from '../../../Helper/CustomSearch'
import axios,{get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from '../../../Helper/Breadcrumbs'


const Target = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let propsState = props.history.location.state;
  let breadcrumbData = [
    {
      title : 'Manage',
      href : '/settings'
    },{
      title : 'Set Target',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tableData,settableData] = useState([]);

  const renderForm = (row) => {
      history.push({
        pathname:"/settings/targetForm",
    })
  }

  const renderOnSearch = searchTerm => {
    get(config.service+'/masters/targetmaster/?&search='+searchTerm,tokenKey).then(res=>{
      settableData(res.data);
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/'
      toast.error('Search Failed', toastAttr);
    })
  }

  const rowEvents = {
    onClick : (e,row) =>{
      history.push({
        pathname:"/settings/targetForm",
        state : {
          id: row.id,
        editMode:true,
        }
    })
    }
  }

  useEffect(() => {
    get(config.service+'/masters/targetmaster/',tokenKey).then(res=>{
      let data = [];
      res.data.vendor_target_list.map(obj => {
        obj.name = obj.vendor_name;
        data.push(obj);
      })
      res.data.employee_target_list.map(obj => {
        obj.name = obj.user_name;
        data.push(obj);
      })
      settableData(data);
    }).catch(err=>{ 
      if(err.response.status === 401) window.location.href = '/'})
  }, []);

  const cols = [
    {
        dataField : 'role',
        text:'Role',
        headerStyle : () => { return{width : '120px'}}
      },{
        dataField : 'name',
        text:'Name',
        headerStyle : () => { return{width : '180px'}}
      },
      {
        dataField : 'target_period',
        text:'Target Period',
        headerStyle : () => { return{width : '120px'}}
      },
      {
        dataField : 'target_assigned',
        text:'Target Assigned',
        headerStyle : () => { return{width : '100px'}}
      },{
        dataField : 'target_achieved',
        text:'Target Achieved',
        headerStyle : () => { return{width : '100px'}}
      },{
        dataField : 'acheivement_percent',
        text:'Achievement Percent',
        headerStyle : () => { return{width : '100px'}}
      },
      {
        dataField : 'id',
        text:'Actions',
        headerStyle : () => { return{width : '100px'}},
        events : {
          OnClick : e=>{return e.stopPropagation(); }
        },
        formatter:(cell,row)=>{
          return(
          <div className='tableActions'>
            <React.Fragment>
              <Link to={{pathname:"/settings/targetForm",state:{id:row.id}}}>
                <FiEdit2 title='Edit' />
              </Link>
            <FiTrash2 className='themeColor actionIcon' onClick={e => deleteRow(row) } />
            </React.Fragment>
          </div>)
        }
      }
  ];

  
  const deleteRow = row => {
    axios.delete(config.service+'/masters/targetmaster/'+row.id,tokenKey).then(resp=>{
      toast.success('Deleted successfully', toastAttr);
      get(config.service+'/masters/targetmaster/',tokenKey).then(res=>{
        settableData(res.data);
      })
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = "/"
      toast.error('Deletion Failed', toastAttr);
    })
  }

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:tableData.length
  })

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
          <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
    <Button variant='primary' onClick={e=>renderForm()}>Create</Button>
  </Col>
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row className='vendorList'>
            <BootstrapTable  bootstrap4  wrapperClasses='table-responsive pad-20' data={tableData} columns={cols} 
    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
    pagination={options} />
            <Col md={6}></Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default Target;
