import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody,Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {BsFillCheckCircleFill,BsFillXCircleFill} from 'react-icons/bs'
import Search from '../../../Helper/CustomSearch'
import axios,{get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from '../../../Helper/Breadcrumbs'
import Loader from '../../../Helper/Loader';


const PaymentLinks = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let breadcrumbData = [
    {
      title : 'Payments',
      href : '/payment'
    },{
      title : 'Link Status',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tableData,settableData] = useState([]);
  const [showModal, setShowModal] = useState(false) 
  const [loading, setloading] = useState(true);
  const [overlay, setOverlay] = useState(false);

  const cols = [
    {
      dataField : 'booking_no',
      text:"Booking No",
      headerStyle : () => { return{width : '150px'}}
    },
    {
      dataField : 'mobile',
      text:"Mobile",
      headerStyle : () => { return{width : '100px'}}
    },
      {
        dataField : 'amount',
        text:"Amount",
        headerStyle : () => { return{width : '150px'}}
      },
      {
        dataField : 'payment_type',
        text:"Payment Type",
        headerStyle : () => { return{width : '150px'}}
      },
      
      {
        dataField : 'created_on',
        text:"Sent on",
        headerStyle : () => { return{width : '180px'}},
        formatter:(cell,row)=>{
            let val = row.created_on ? row.created_on.replace('T',' ').replace('Z','').split('.')[0] : '';
            return(
            <div style={{'whiteSpace':'nowrap'}}>{val.slice(0,19)}</div>)
          }
      },
      // {
      //   dataField : 'active',
      //   text:"Is Active",
      //   headerStyle : () => { return{width : '60px'}},
      //   formatter:(cell,row)=>{
      //     if(cell) return(<div style={{textAlign:'center', fontSize:'0.9rem'}}><BsFillCheckCircleFill className='color-green' /></div>)
      //     else return(<div style={{textAlign:'center', fontSize:'0.9rem'}}><BsFillXCircleFill className='color-red' /></div>)
      //     }
      // },
    // {
    //   dataField : 'id',
    //   text:'Actions',
    //   headerStyle : () => { return{width : '50px'}},
    //   events : {
    //     OnClick : e=>{return e.stopPropagation(); }
    //   },
    //   formatter:(cell,row)=>{
    //     return(
    //     <div className='tableActions'>
    //       <React.Fragment>
    //         <Link to={{pathname:"/payment/logForm",state:{id:row.id}}}>
    //           <BiShowAlt title='View' />
    //         </Link>
    //       </React.Fragment>
    //     </div>)
    //   }
    // }
  ];

  const setLoader = (val) => {
    setloading(val);setOverlay(val);
  }

  const renderOnSearch = (searchTerm) => {
    setLoader(true);
    get(config.service+'/paymentlinklogsearch/?mobileno='+searchTerm,tokenKey).then(res=>{
      settableData(res.data);
      setLoader(false);
    }).catch(err=>{
        setLoader(false);
      if(err.response.status === 401) window.location.href = '/'
      toast.error('Search Failed', toastAttr);
    })
  }

  const rowEvents = {
    onClick : (e,row) =>{
      history.push({
        pathname:"/payment/linkForm",
        state : {
          id: row.id,
        editMode:true,
        }
    })
    }     
  }

  useEffect(() => {
    get(config.service+'/paymentlinklog/',tokenKey).then(res=>{
      settableData(res.data);
      setLoader(false);
    }).catch(err=>{ 
        setLoader(false);
      if(err.response.status === 401) window.location.href = '/'})
  }, []);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    totalSize:tableData.length,
    hidePageListOnlyOnePage : true,
    // showTotal  : true
  })

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
        <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
  </Col>
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row className='vendorList'>
        <Loader loading={loading} overlay={overlay}>
            <BootstrapTable  bootstrap4 wrapperClasses='table-responsive  pad-20' data={tableData} columns={cols} 
    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
    pagination={options} />
    </Loader>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default PaymentLinks;