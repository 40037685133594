import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import StatusSteps from "../../../Helper/StatusSteps";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import Loader from "../../../Helper/Loader";
import empty from "is-empty";
import CustomSwitch from "../../../Helper/CustomSwitch";
// import DateTimePicker from 'react-datetime-picker';
import OTPInput, { ResendOTP } from "otp-input-react";
import moment from "moment";
import Select from "react-select";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import VoucherForm from "../../Booking/views/VoucherForm";
import ErrorHandling from "../../../Helper/ErrorHandling";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import useDebounce from "../../../CustomHooks/useDebounce";
import { Autocomplete } from "@mui/material";

const BookingForm = (props) => {
  let history = useHistory();
  let { bookingId } = useParams();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [OTP, setOTP] = useState("");
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [stateList, setstateList] = useState([]);
  const [state, setstate] = useState(null);
  const [mandapList, setmandapList] = useState([]);
  const [mandap, setmandap] = useState(null);
  const [vendorList, setvendorList] = useState([]);
  //   const [vendor, setvendor] = useState(null);
  const [vendor, setVendor] = useState([]);

  const [areaList, setareaList] = useState([]);
  const [area, setarea] = useState(null);
  const [funcTypeList, setfuncTypeList] = useState([]);
  const [funcType, setfuncType] = useState(null);
  const [otpVerified, setOTPVerified] = useState(false);
  const [payDetails, setPayDetails] = useState({});
  const [showPayModal, setshowPayModal] = useState(false);
  const [payErrors, setPayErrors] = useState({});
  const [showVoucherForm, setshowVoucherForm] = useState(false);
  const [mandapData, setmandapData] = useState([]);
  const [formId, setformId] = useState(bookingId ? bookingId : propsState.id);
  const [handleErr, sethandleErr] = useState(false);
  const [errData, seterrData] = useState(null);
  const [bookingType, setbookingType] = useState(null);
  const [startTime, setstartTime] = useState(null);
  const [endTime, setendTime] = useState(null);
  const [vendorName, setvendorName] = useState(null);
  const [selectDropDownString, setSelectDropDownString] = useState("");

  let breadcrumbData = [
    {
      title: "Bookings",
      href: "/booking",
    },
    {
      title: editMode || bookingId ? "View" : "Create",
      href: "#",
    },
  ];
  let isAdminUser = sessionStorage.getItem("permission")
    ? JSON.parse(sessionStorage.getItem("permission"))[0].user_role ===
        "admin" || false
    : false;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const bookingTypes = [
    { value: "full_day", label: "Full day" },
    { value: "half_day_morning", label: "Half a day - Morning" },
    { value: "half_day_evening", label: "Half a day - Evening" },
    { value: "custom", label: "Custom" },
  ];

  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Details",
      id: "details",
      show: true,
    },
  ]);

  const toggleModal = () => {
    setShowModal(!showModal);
    setOTP("");
  };

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };
  const handleSelect = (keyName, setFn, e) => {
    if (keyName === "vendor_id") {
      setmandap(null);
      setForm({
        ...form,
        mandap_id: "",
      });
      if (e && e.value) {
        let temp = mandapData.filter((obj) => obj.vendor_id === e.value),
          list = [];
        for (let i in temp)
          list.push({ label: temp[i].mandap_name, value: temp[i].id });
        setmandapList(list);
      } else setmandapList([]);
    }
    setFn(e);
    setForm({
      ...form,
      [keyName]: (e && e.value) || null,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };
  useEffect(() => {
    handleBookingDate(form.booking_date);
  }, [form.mandap_id, form.mandap_function_type]);

  const handleBookingDate = (newValue) => {
    if (newValue) {
      setLoader(true);
      let data = {
        mandap_id: form.mandap_id,
        muhurtham_date: moment(newValue).format("YYYY-MM-DD"),
      };
      let temp = mandapData.filter((obj) => obj.id === form.mandap_id);
      if (temp[0] && temp[0].have_non_muhurtham_price)
        post(config.service + "/masters/check-muhurtham-date/", data, {
          headers: tokenKey,
        })
          .then((res) => {
            setForm({ ...form, booking_date: newValue });
            handleBookingType(bookingType, newValue);
            setErrors({
              ...errors,
              booking_date: null,
            });
            setLoader(false);
          })
          .catch((err) => {
            if (err.response.status === 401) window.location.href = "/";
            else {
              get(
                config.service +
                  "/masters/mandap-functiontype-pricing/" +
                  form.mandap_id +
                  "/",
                { headers: tokenKey }
              ).then((res) => {
                let isValueSet = false;
                res.data.map((obj) => {
                  if (obj.function_type === form.mandap_function_type) {
                    setForm({
                      ...form,
                      amount: obj.price,
                      booking_date: newValue,
                    });
                    isValueSet = true;
                  }
                });
                if (!isValueSet) setForm({ ...form, booking_date: newValue });
                setErrors({
                  ...errors,
                  booking_date: null,
                });
                setLoader(false);
              });
            }
          });
      else {
        setForm({ ...form, booking_date: newValue });
        setErrors({
          ...errors,
          booking_date: null,
        });
        setLoader(false);
      }
    }
  };

  const handleBookingType = (e, bookingDate) => {
    setLoader(true);
    if (form.mandap_id && form.mandap_function_type)
      get(
        config.service +
          "/masters/mandap_pricing/list/?&mandap_id=" +
          form.mandap_id,
        { headers: tokenKey }
      )
        .then((res) => {
          if (empty(res.data)) {
            setbookingType(e);
            setForm({
              ...form,
              booking_from: null,
              booking_to: null,
              timing: (e && e.value) || null,
              amount: "",
              reservation_price: "",
              booking_date: bookingDate ? bookingDate : form.booking_date,
            });
          } else {
            let pricing = res.data[0],
              totalAmount = "",
              startTime = null,
              endTime = null;
            if (e && e.value === "full_day") {
              totalAmount = pricing.fd_price;
              startTime =
                (pricing.fd_start_time &&
                  pricing.fd_start_time.slice(
                    0,
                    pricing.fd_start_time.lastIndexOf(":")
                  )) ||
                "00:00";
              endTime =
                (pricing.fd_end_time &&
                  pricing.fd_end_time.slice(
                    0,
                    pricing.fd_end_time.lastIndexOf(":")
                  )) ||
                "23:59";
            } else if (e && e.value === "half_day_morning") {
              totalAmount = pricing.hf_am_price;
              startTime =
                (pricing.hf_am_start_time &&
                  pricing.hf_am_start_time.slice(
                    0,
                    pricing.hf_am_start_time.lastIndexOf(":")
                  )) ||
                "00:00";
              endTime =
                (pricing.hf_am_end_time &&
                  pricing.hf_am_end_time.slice(
                    0,
                    pricing.hf_am_end_time.lastIndexOf(":")
                  )) ||
                "16:00";
            } else if (e && e.value === "half_day_evening") {
              totalAmount = pricing.hf_pm_price;
              startTime =
                (pricing.hf_pm_start_time &&
                  pricing.hf_pm_start_time.slice(
                    0,
                    pricing.hf_pm_start_time.lastIndexOf(":")
                  )) ||
                "17:00";
              endTime =
                (pricing.hf_pm_end_time &&
                  pricing.hf_pm_end_time.slice(
                    0,
                    pricing.hf_pm_end_time.lastIndexOf(":")
                  )) ||
                "23:59";
            }
            const d = moment(new Date()).format("L");
            let startTimeVal = moment(d + " " + startTime).format();
            let endTimeVal = moment(d + " " + endTime).format();

            setbookingType(e);
            setForm({
              ...form,
              booking_from: null,
              booking_to: null,
              timing: (e && e.value) || null,
              start_time: startTime,
              end_time: endTime,
              amount: totalAmount ? totalAmount : "",
              reservation_price:
                e && e.value === "custom"
                  ? ""
                  : e && e.value
                  ? pricing.reservation_price
                  : "",
              booking_date: bookingDate ? bookingDate : form.booking_date,
            });
            setstartTime(startTimeVal);
            setendTime(endTimeVal);
            if (!!errors["amount"] && !empty(totalAmount))
              setErrors({
                ...errors,
                amount: null,
              });
          }
          if (!!errors["timing"])
            setErrors({
              ...errors,
              timing: null,
            });

          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          if (err.response.status === 401) window.location.href = "/";
        });
    else {
      toast.error(
        "Select a Mandap Name and Function Type to get booking price details",
        toastAttr
      );
      setLoader(false);
    }
  };

  const onChangeTime = (value, keyName, setFn) => {
    let val = moment(value).format("HH:mm:ss");
    setFn(value);
    setForm({
      ...form,
      [keyName]: val,
    });
  };

  const handleCheckBox = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const handleDate = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    history.push("/booking");
  };

  const findErrors = () => {
    const {
      customer_name,
      mobile,
      address_line_1,
      area,
      state_id,
      pincode,
      mandap_function_type,
      amount,
      timing,
      mandap_id,
      vendor_id,
      reservation_price,
      booking_from,
      booking_to,
      booking_date,
    } = form;
    const newErrors = {};
    if (empty(customer_name))
      newErrors.customer_name = "This is a required field";
    if (empty(mobile)) newErrors.mobile = "This is a required field";
    if (empty(address_line_1))
      newErrors.address_line_1 = "This is a required field";
    if (empty(area)) newErrors.area = "This is a required field";
    if (empty(state_id)) newErrors.state_id = "This is a required field";
    if (empty(pincode)) newErrors.pincode = "This is a required field";
    if (empty(mandap_id)) newErrors.mandap_id = "This is a required field";
    if (empty(mandap_function_type))
      newErrors.mandap_function_type = "This is a required field";
    if (empty(amount)) newErrors.amount = "This is a required field";
    if (empty(timing)) newErrors.timing = "This is a required field";
    if (empty(vendor_id)) newErrors.vendor_id = "This is a required field";
    if (empty(reservation_price))
      newErrors.reservation_price = "This is a required field";
    if (bookingType && bookingType.value === "custom" && empty(booking_from))
      newErrors.booking_from = "This is a required field";
    if (bookingType && bookingType.value === "custom" && empty(booking_to))
      newErrors.booking_to = "This is a required field";
    if (bookingType && bookingType.value !== "custom" && empty(booking_date))
      newErrors.booking_date = "This is a required field";

    return newErrors;
  };

  const saveForm = () => {
    let id = editMode ? formId : null,
      method = editMode ? "put" : "post";
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? config.service + "/booking/update/"
          : config.service + "/booking/create/",
        data = JSON.parse(JSON.stringify(form));
      if (data.timing === "custom") {
        if (data.booking_from)
          data.booking_from = moment(form.booking_from).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        if (data.booking_to)
          data.booking_to = moment(form.booking_to).format(
            "YYYY-MM-DD HH:mm:ss"
          );
      } else {
        delete data["booking_from"];
        delete data["booking_to"];
      }
      if (data.booking_date)
        data.booking_date = data.booking_date.split("T")[0];
      // data.vendor_id=JSON.parse(sessionStorage.getItem('permission'))[0].vendor_ids[0]
      if (editMode) data.booking_id = data.id;
      else data.otp_confirmation = otpVerified;
      axios({
        url: url,
        method: method,
        headers: tokenKey,
        data: data,
      })
        .then((res) => {
          if (res.data.booking_from && res.data.booking_to) {
            let fromDate = res.data.booking_from
                .replace("T", " ")
                .replace("Z", ""),
              toDate = res.data.booking_to.replace("T", " ").replace("Z", "");
            res.data.booking_from = new Date(fromDate);
            res.data.booking_to = new Date(toDate);
          }
          res.data.vendor_id = res.data.vendor;
          setForm(res.data);
          setEditMode(true);
          toast.success("Saved successfully", toastAttr);
          setLoader(false);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else {
            seterrData(err.response && err.response.data);
            sethandleErr(true);
          }
          toast.error("Saving Failed", toastAttr);
          setLoader(false);
        });
    }
  };

  const closeErrorHandling = () => {
    sethandleErr(false);
    seterrData(null);
  };

  const getApprovalBtnValue = () => {
    let status = form.status,
      val = "",
      statusMap = {
        draft: "Confirm Booking",
        blocked: "Confirm Booking",
        booking_confirmed: "Issue Voucher",
        // voucher_issued : 'Redeem Voucher',
        voucher_redeemed: "Encash",
      };
    if (!isAdminUser && statusMap[status] !== "Confirm Booking")
      // include enquiry,quotation status
      return false;
    else return statusMap[status];
  };

  const triggerApproval = (btnName) => {
    if (btnName === "Issue Voucher") {
      setshowVoucherForm(true);
    } else {
      setLoader(true);
      const newErrors = findErrors();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        toast.error("Enter all required details to proceed", toastAttr);
        setLoader(false);
      } else {
        let mapStatus = {
          "Confirm Booking": "booking_confirmed",
          "Issue Voucher": "voucher_issued",
          "Redeem Voucher": "voucher_redeemed",
          Encash: "encash",
          cancel: "cancelled",
        };
        let data = {
          status: mapStatus[btnName],
          booking_id: form.id,
        };
        patch(config.service + "/booking/update/", data, { headers: tokenKey })
          .then((res) => {
            toast.success("Action perfomed successfully", toastAttr);
            get(config.service + "/booking/" + form.id, {
              headers: tokenKey,
            }).then((res) => {
              if (res.data.booking_from && res.data.booking_to) {
                let fromDate = res.data.booking_from
                    .replace("T", " ")
                    .replace("Z", ""),
                  toDate = res.data.booking_to
                    .replace("T", " ")
                    .replace("Z", "");
                res.data.booking_from = new Date(fromDate);
                res.data.booking_to = new Date(toDate);
              }
              res.data.vendor_id = res.data.vendor;
              setForm(res.data);
              setLoader(false);
            });
          })
          .catch((err) => {
            if (err.response.status === 401) window.location.href = "/";
            if (err.response && err.response.data && err.response.data.message)
              toast.error(err.response.data.message, toastAttr);
            else toast.error("Action failed", toastAttr);
            setLoader(false);
          });
      }
    }
  };

  const issueVoucher = (val) => {
    let data = { ...val, booking_id: form.id, customer_id: form.customer_id },
      updateData = { status: "voucher_issued", booking_id: form.id };
    post(config.service + "/booking/voucher/create/", data, {
      headers: tokenKey,
    })
      .then((res) => {
        toast.success("Voucher created successfully", toastAttr);
        patch(config.service + "/booking/update/", updateData, {
          headers: tokenKey,
        }).then((res) => {
          get(config.service + "/booking/" + form.id, {
            headers: tokenKey,
          }).then((res) => {
            if (res.data.booking_from && res.data.booking_to) {
              let fromDate = res.data.booking_from
                  .replace("T", " ")
                  .replace("Z", ""),
                toDate = res.data.booking_to.replace("T", " ").replace("Z", "");
              res.data.booking_from = new Date(fromDate);
              res.data.booking_to = new Date(toDate);
            }
            res.data.vendor_id = res.data.vendor;
            setForm(res.data);
            setshowVoucherForm(false);
            setLoader(false);
          });
        });
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message, toastAttr);
        else toast.error("Action failed", toastAttr);
        setLoader(false);
      });
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const genOTP = () => {
    if (form.mobile) {
      let data = {
        mobile: form.mobile,
        user_type: "customer",
      };
      post(config.service + "/sms/generateOtp/", data, { headers: tokenKey })
        .then((res) => {
          setShowModal(true);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          toast.error("OTP generation failed", toastAttr);
        });
    } else toast.error("Enter mobile number to receive OTP", toastAttr);
  };

  const verifyOTP = () => {
    let data = {
      mobile: form.mobile,
      user_type: "customer",
      otp: OTP,
    };
    post(config.service + "/sms/verifyOtp/", data, { headers: tokenKey })
      .then((res) => {
        toast.success("OTP verified successfully", toastAttr);
        toggleModal();
        setOTPVerified(true);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("OTP verification failed", toastAttr);
      });
  };

  const renderButton = (buttonProps) => {
    return (
      <button className="btn btn-primary" {...buttonProps}>
        Resend OTP
      </button>
    );
  };
  const renderTime = (remainingTime) => {
    return <span>{remainingTime} seconds remaining</span>;
  };

  const verifyLead = () => {
    if (empty(form.pincode) || empty(form.mandap_function_type)) {
      toast.error("Enter Pincode and Function Type to proceed", toastAttr);
    } else {
      let data = {
        booking_id: form.id,
        pincode: form.pincode,
        enquiry_id: null,
        function_type_id: form.mandap_function_type,
      };
      setLoader(true);
      post(config.service + "/kalyan-uat/export-lead-data/", data, {
        headers: tokenKey,
      })
        .then((res) => {
          toast.success("Verified Successfully", toastAttr);
          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else toast.error("Verification Failed", toastAttr);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (bookingId) setEditMode(true);
    get(config.service + "/booking/getBookingMasters/", { headers: tokenKey })
      .then((res) => {
        let state = [],
          mandap = [],
          vendor = [],
          area = [],
          funcType = [],
          data = res.data;
        for (let i in data.state)
          state.push({ label: data.state[i].name, value: data.state[i].id });
        for (let i in data.mandap)
          mandap.push({
            label: data.mandap[i].mandap_name,
            value: data.mandap[i].id,
          });
        for (let i in data.vendor)
          vendor.push({
            label: data.vendor[i].vendor_name,
            value: data.vendor[i].id,
          });
        for (let i in data.area)
          area.push({ label: data.area[i].name, value: data.area[i].id });
        for (let i in data.function_type)
          funcType.push({
            label: data.function_type[i].function_type,
            value: data.function_type[i].id,
          });
        setmandapData(data.mandap);
        setstateList(state);
        setmandapList(mandap);
        // setvendorList(vendor);
        setareaList(area);
        setfuncTypeList(funcType);
        if ((propsState && propsState.editMode) || bookingId)
          get(config.service + "/booking/" + formId, {
            headers: tokenKey,
          }).then((res) => {
            if (res.data.booking_from && res.data.booking_to) {
              let fromDate = res.data.booking_from
                  .replace("T", " ")
                  .replace("Z", ""),
                toDate = res.data.booking_to.replace("T", " ").replace("Z", "");
              res.data.booking_from = new Date(fromDate);
              res.data.booking_to = new Date(toDate);
            }
            let val = state.filter((temp) => temp.value === res.data.state_id);
            let mandapVal = mandap.filter(
              (temp) => temp.value === res.data.mandap_id
            );
            let vendorVal = vendor.filter(
              (temp) => temp.value === res.data.vendor
            );
            let areaVal = area.filter((temp) => temp.value === res.data.area);
            let funcTypeVal = funcType.filter(
              (temp) => temp.value === res.data.mandap_function_type
            );
            let timingVal = bookingTypes.filter(
              (temp) => temp.value === res.data.timing
            );
            res.data.vendor_id = res.data.vendor;
            const d = moment(new Date()).format("L");
            let startTimeVal = moment(d + " " + res.data.start_time).format();
            let endTimeVal = moment(d + " " + res.data.end_time).format();
            setstartTime(startTimeVal);
            setendTime(endTimeVal);
            setstate(val[0]);
            setmandap(mandapVal[0]);
            setVendor(vendorVal[0]);
            setarea(areaVal);
            setfuncType(funcTypeVal);
            setbookingType(timingVal[0]);
            setForm(res.data);
            setOTPVerified(true);
            setLoader(false);
          });
        else setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error("Error", toastAttr);
      });
  }, []);

  const showPayDetailsModal = () => {
    setPayDetails({
      mobile: form.mobile,
      email: form.email,
    });
    setshowPayModal(true);
  };

  const togglePayModal = () => {
    setShowModal(!showModal);
    setOTP("");
  };

  const handleModalClose = () => {
    setshowPayModal(false);
  };

  const handlePayInput = (keyName, value) => {
    setPayDetails({
      ...payDetails,
      [keyName]: value,
    });
    if (keyName && !!payErrors[keyName])
      setPayErrors({
        ...payErrors,
        [keyName]: null,
      });
  };

  const proceedToPayment = () => {
    const { mobile, amountToPay } = payDetails;
    const newErrors = {};
    if (empty(mobile)) newErrors.mobile = "This is a required field";
    if (empty(amountToPay)) newErrors.amountToPay = "This is a required field";
    if (Object.keys(newErrors).length > 0) {
      setPayErrors(newErrors);
      toast.error("Enter the required details to proceed", toastAttr);
    } else if (amountToPay > form.amount) {
      toast.error(
        "Entered amount is more than the total amount. Kindly change",
        toastAttr
      );
    } else {
      setLoader(true);
      let data = {
        mobile: payDetails.mobile,
        email: payDetails.email,
        advance: true,
        amount: payDetails.amountToPay,
        alternate_mobile: false,
        customer_id: form.customer_id,
        booking_id: form.id,
      };
      post(config.service + "/booking/paymentlink/create/", data, {
        headers: tokenKey,
      })
        .then((res) => {
          // sessionStorage.setItem('bookingPayDetails', res.data);
          setshowPayModal(false);
          setLoader(false);
          toast.success(
            "Payment link is sent to the given mobile number / email",
            toastAttr
          );
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          toast.error("Payment link generation failed", toastAttr);
          setLoader(false);
          setshowPayModal(false);
        });
    }
  };

  const onChangeDateTime = (value, keyName) => {
    let val = moment(value).format("YYYY-MM-DD HH:mm:ss");
    setForm({
      ...form,
      [keyName]: val,
    });
    setErrors({
      ...errors,
      [keyName]: null,
    });
  };

  const redirectTo = (path, keyName) => {
    let mandapName = mandap ? mandap.label : "details";
    history.push({
      pathname: path,
      state: {
        bookingId: form.id,
        breadcrumbData: [
          {
            title: "Bookings",
            href: "/booking",
          },
          {
            title: "Booking - " + mandapName,
            href: "/bookingForm/" + form.id,
          },
          {
            title: "View " + keyName,
            href: "#",
          },
        ],
      },
    });
  };

  let approvalBtn = getApprovalBtnValue();

  const handleInputChange = (event, newInputValue) => {
    setSelectDropDownString(newInputValue);
    setvendorName(newInputValue);
    setVendor(newInputValue);
  };

  useEffect(() => {
    if (props?.mandap?.vendor_id !== undefined) {
      axios
        .get(
          config.service +
            `/masters/base/vendor/?&id=${props?.mandap?.vendor_id}`
        )
        .then((res) => {
          setvendorName(res?.data?.results[0]?.vendor_name);
        });
    }
  }, [props]);

  // useDebounce hook for dropDown menu
  const debouncedValue = useDebounce(selectDropDownString, 500);
  const search = useCallback(async () => {
    get(
      config.service + `/masters/base/vendor/?&search=${debouncedValue}`,
      tokenKey
    ).then((res) => {
      let vendors = [];
      res?.data?.results?.map((obj) => {
        vendors.push({ value: obj.id, label: obj.vendor_name });
      });
      setvendorList(vendors);
    });
  }, [debouncedValue]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv">
                <Col md={7}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                  {!editMode ? (
                    <Button variant="primary" onClick={(e) => genOTP()}>
                      Generate OTP
                    </Button>
                  ) : (
                    ""
                  )}
                  {editMode ? (
                    <Button variant="primary" onClick={verifyLead}>
                      Push to ILEAD
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
                {editMode ? (
                  <Col md={5} className="navigations">
                    <div
                      className="softButton"
                      title="View Payments"
                      onClick={() => redirectTo("/viewPayment", "Payments")}
                    >
                      <span>PAYMENTS</span>
                    </div>
                    <div
                      className="softButton"
                      title="View Vouchers"
                      onClick={() => redirectTo("/voucher", "Vouchers")}
                    >
                      <span>VOUCHERS</span>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <Row className="formBtnsDiv">
                <Col md={12} style={{ margin: "5px 0" }}>
                  {editMode && approvalBtn ? (
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        triggerApproval(approvalBtn);
                      }}
                    >
                      {approvalBtn}
                    </Button>
                  ) : (
                    ""
                  )}
                  {editMode ? (
                    <>
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          showPayDetailsModal();
                        }}
                      >
                        Proceed to Payment
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                  {editMode && form.status && form.status !== "cancelled" ? (
                    <Button
                      variant="secondary"
                      onClick={(e) => {
                        triggerApproval("cancel");
                      }}
                    >
                      Cancel Booking
                    </Button>
                  ) : (
                    ""
                  )}
                  {/* {editMode && (form.status && form.status === 'booking_confirmed') ? <Button variant='primary' className='bwgColor' onClick={e=>setshowVoucherForm(true)}>Send Voucher</Button> : ''} */}
                  {editMode ? (
                    <StatusSteps data={{ status: form.status }} />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      {tab.details ? (
                        <Row>
                          <Col md={12}>
                            <Loader loading={loading} overlay={overlay}>
                              <fieldset className="border p-2 fieldsetCls">
                                <legend className="w-auto">
                                  Booking Details
                                </legend>
                                <Row>
                                  <Col md={6}>
                                    {editMode ? (
                                      <Row>
                                        <Col md={4}>
                                          <Form.Label>
                                            Booking Ref. Number
                                          </Form.Label>
                                        </Col>
                                        <Col md={7}>
                                          <Form.Control
                                            disabled
                                            className="bookingNum"
                                            type="text"
                                            name="booking_no"
                                            value={form.booking_no}
                                          />
                                        </Col>
                                      </Row>
                                    ) : (
                                      ""
                                    )}
                                    <Row>
                                      <Col md={4}>
                                        <label>Vendor</label>
                                      </Col>
                                      <Col md={7}>
                                        {/* <Select
                                          className={`selectBox ${
                                            !!errors.vendor_id
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={vendor}
                                          onChange={(e) =>
                                            handleSelect(
                                              "vendor_id",
                                              setvendor,
                                              e
                                            )
                                          }
                                          options={vendorList}
                                          isClearable
                                          isDisabled={
                                            props.propsData &&
                                            props.propsData.editMode
                                          }
                                        /> */}

                                        <Autocomplete
                                          className={`selectBox ${
                                            !!errors.vendor_id
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={vendor}
                                          inputValue={vendorName}
                                          onInputChange={(
                                            event,
                                            newInputValue
                                          ) => {
                                            handleInputChange(
                                              event,
                                              newInputValue
                                            );
                                            setForm({
                                              ...form,
                                              vendor_id: newInputValue || null,
                                            });
                                          }}
                                          id="controllable-states-demo"
                                          options={vendorList}
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          isClearable
                                        />
                                        {errors.vendor_id ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.vendor_id}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Mandap Name</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.mandap_id
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={mandap}
                                          onChange={(e) =>
                                            handleSelect(
                                              "mandap_id",
                                              setmandap,
                                              e
                                            )
                                          }
                                          options={mandapList}
                                          isClearable
                                        />
                                        {errors.mandap_id ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.mandap_id}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Function Type</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.mandap_function_type
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={funcType}
                                          onChange={(e) =>
                                            handleSelect(
                                              "mandap_function_type",
                                              setfuncType,
                                              e
                                            )
                                          }
                                          options={funcTypeList}
                                          isClearable
                                        />
                                        {errors.mandap_function_type ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.mandap_function_type}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Total Amount</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="number"
                                          name="amount"
                                          value={form.amount}
                                          onChange={(e) =>
                                            handleInput(
                                              "amount",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.amount}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.amount}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={6}>
                                    {/* <Row>
                                                <Col md={4}>
                                                    <Form.Label>Booking From</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                    <DateTimePicker required minDate={new Date()} 
                                                     name="booking_from" value={form.booking_from} onChange={val => {handleDate('booking_from',val)}} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.booking_from }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Booking To</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <DateTimePicker required minDate={form.booking_from}
                                                 name="booking_to" value={form.booking_to} onChange={val => {handleDate('booking_to',val)}} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.booking_to }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row> */}
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Booking Type</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.timing
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={bookingType}
                                          onChange={(e) => handleBookingType(e)}
                                          options={bookingTypes}
                                          isClearable
                                        />
                                        {errors.timing ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.timing}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    {bookingType &&
                                    bookingType.value !== "custom" ? (
                                      <>
                                        <Row>
                                          <Col md={4}>
                                            <Form.Label>
                                              Booking Date
                                            </Form.Label>
                                          </Col>
                                          <Col md={7} className="datePicker">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDateFns}
                                            >
                                              <DatePicker
                                                inputFormat="dd-MM-yyyy"
                                                label=""
                                                value={
                                                  form.booking_date || null
                                                }
                                                onChange={(newValue) =>
                                                  handleBookingDate(newValue)
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    className="form-control"
                                                    {...params}
                                                  />
                                                )}
                                              />
                                              {errors.booking_date ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {errors.booking_date}
                                                </Form.Control.Feedback>
                                              ) : (
                                                ""
                                              )}
                                            </LocalizationProvider>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={4}>
                                            <label> Start Time</label>
                                          </Col>
                                          <Col md={7} className="timePicker">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDateFns}
                                            >
                                              <TimePicker
                                                value={startTime}
                                                onChange={(newValue) => {
                                                  onChangeTime(
                                                    newValue,
                                                    "start_time",
                                                    setstartTime
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={4}>
                                            <label> End Time</label>
                                          </Col>
                                          <Col md={7} className="timePicker">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDateFns}
                                            >
                                              <TimePicker
                                                value={endTime}
                                                onChange={(newValue) => {
                                                  onChangeTime(
                                                    newValue,
                                                    "end_time",
                                                    setendTime
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <TextField {...params} />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : bookingType &&
                                      bookingType.value === "custom" ? (
                                      <>
                                        <Row>
                                          <Col md={4}>
                                            <Form.Label>
                                              Booking From
                                            </Form.Label>
                                          </Col>
                                          <Col
                                            md={7}
                                            className="dateTimePicker"
                                          >
                                            <LocalizationProvider
                                              dateAdapter={AdapterDateFns}
                                            >
                                              <DateTimePicker
                                                label=""
                                                value={
                                                  form.booking_from || null
                                                }
                                                onChange={(newValue) =>
                                                  onChangeDateTime(
                                                    newValue,
                                                    "booking_from"
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    className="form-control"
                                                    {...params}
                                                  />
                                                )}
                                              />
                                              {errors.booking_from ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {errors.booking_from}
                                                </Form.Control.Feedback>
                                              ) : (
                                                ""
                                              )}
                                            </LocalizationProvider>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={4}>
                                            <Form.Label>Booking To</Form.Label>
                                          </Col>
                                          <Col
                                            md={7}
                                            className="dateTimePicker"
                                          >
                                            <LocalizationProvider
                                              dateAdapter={AdapterDateFns}
                                            >
                                              <DateTimePicker
                                                label=""
                                                value={form.booking_to || null}
                                                onChange={(newValue) =>
                                                  onChangeDateTime(
                                                    newValue,
                                                    "booking_to"
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    className="form-control"
                                                    {...params}
                                                  />
                                                )}
                                              />
                                              {errors.booking_to ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {errors.booking_to}
                                                </Form.Control.Feedback>
                                              ) : (
                                                ""
                                              )}
                                            </LocalizationProvider>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Blocking Price</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="form.reservation_price"
                                          value={form.reservation_price}
                                          onChange={(e) =>
                                            handleInput(
                                              "reservation_price",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.reservation_price}
                                        />
                                        {errors.reservation_price ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.reservation_price}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </fieldset>
                              <fieldset className="border p-2 fieldsetCls">
                                <legend className="w-auto">
                                  Customer Details
                                </legend>
                                <Row>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Customer Name</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="customer_name"
                                          value={form.customer_name}
                                          onChange={(e) =>
                                            handleInput(
                                              "customer_name",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.customer_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.customer_name}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Mobile</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="mobile"
                                          value={form.mobile}
                                          onChange={(e) =>
                                            handleInput(
                                              "mobile",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.mobile}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.mobile}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Email</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="email"
                                          name="email"
                                          value={form.email}
                                          onChange={(e) =>
                                            handleInput("email", e.target.value)
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <label>
                                          Share Promotions in WhatsApp
                                        </label>
                                      </Col>
                                      <Col md={7}>
                                        <CustomSwitch
                                          handleChange={handleCheckBox}
                                          name="whatsapp_share"
                                          value={form.whatsapp_share}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </fieldset>
                              <fieldset className="border p-2 fieldsetCls">
                                <legend className="w-auto">Address</legend>
                                <Row>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <label>Address Line 1</label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          as="textarea"
                                          rows="2"
                                          cols="50"
                                          name="address_line_1"
                                          value={form.address_line_1}
                                          onChange={(e) =>
                                            handleInput(
                                              "address_line_1",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.address_line_1}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.address_line_1}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <label>Address Line 2</label>
                                      </Col>
                                      <Col md={7}>
                                        <textarea
                                          rows="2"
                                          cols="50"
                                          name="address_line_2"
                                          value={form.address_line_2}
                                          onChange={(e) =>
                                            handleInput(
                                              "address_line_2",
                                              e.target.value
                                            )
                                          }
                                        >
                                          {form.address_line_2}
                                        </textarea>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={6}>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Area</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.area ? "inValidField" : ""
                                          }`}
                                          value={area}
                                          onChange={(e) =>
                                            handleSelect("area", setarea, e)
                                          }
                                          options={areaList}
                                          isClearable
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>State</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Select
                                          className={`selectBox ${
                                            !!errors.state_id
                                              ? "inValidField"
                                              : ""
                                          }`}
                                          value={state}
                                          onChange={(e) =>
                                            handleSelect(
                                              "state_id",
                                              setstate,
                                              e
                                            )
                                          }
                                          options={stateList}
                                          isClearable
                                        />
                                        {errors.state_id ? (
                                          <Form.Control.Feedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors.state_id}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Pincode</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="pincode"
                                          value={form.pincode}
                                          onChange={(e) =>
                                            handleInput(
                                              "pincode",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.pincode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.pincode}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </fieldset>
                              <Row>
                                <Col md={12} className="unlock">
                                  <CustomSwitch
                                    handleChange={handleCheckBox}
                                    name="unlock_special_discount"
                                    value={form.unlock_special_discount}
                                    className="switch"
                                  />
                                  <div className="text">
                                    {" "}
                                    Are you interested to unlock special
                                    discounts upto Rs.50000? If yes, our Kalyan
                                    representative will contact you
                                  </div>
                                </Col>
                              </Row>
                              {/* <fieldset className="border p-2 fieldsetCls">
                                <legend className="w-auto">Bride & Groom Details</legend>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Bride Name</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="bride_name" value={form.bride_name} onChange={e=> handleInput('bride_name',e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Bride Mobile</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="bride_mobile" value={form.bride_mobile} onChange={e=> handleInput('bride_mobile',e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Bride Email</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='email' name="bride_email" value={form.bride_email} onChange={e=> handleInput('bride_email',e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <label>Active</label>
                                                </Col>
                                                <Col md={7}>
                                                <CustomSwitch handleChange={handleCheckBox} name = 'active' value={form.active} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                        <Row>
                                                <Col md={4}>
                                                    <Form.Label>Groom Name</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="groom_name" value={form.groom_name} onChange={e=> handleInput('groom_name',e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Groom Mobile</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="groom_mobile" value={form.groom_mobile} onChange={e=> handleInput('groom_mobile',e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Groom Email</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='email' name="groom_email" value={form.groom_email} onChange={e=> handleInput('groom_email',e.target.value)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                            </fieldset> */}
                            </Loader>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Form>
                  </div>
                </Col>
              </Row>
              <Modal
                toggle={(e) => {
                  toggleModal();
                }}
                isOpen={showModal}
                className="otpModal"
              >
                <ModalHeader
                  toggle={(e) => {
                    toggleModal();
                  }}
                >
                  Verify OTP
                </ModalHeader>
                <ModalBody>
                  <div className="otpCont">
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      secure
                    />
                    <ResendOTP
                      className="otpResend"
                      renderButton={renderButton}
                      renderTime={renderTime}
                      onResendClick={() => genOTP}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="primary"
                    disabled={empty(OTP)}
                    onClick={(e) => {
                      verifyOTP();
                    }}
                  >
                    Verify
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal
                toggle={(e) => {
                  handleModalClose();
                }}
                isOpen={showPayModal}
                className="otpModal"
              >
                <ModalHeader
                  toggle={(e) => {
                    handleModalClose();
                  }}
                >
                  Payment Details
                </ModalHeader>
                <ModalBody>
                  <div className="theme-light">
                    <Form className="form defaultForm">
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={4}>
                              <Form.Label>Mobile</Form.Label>
                            </Col>
                            <Col md={7}>
                              <Form.Control
                                type="number"
                                name="mobile"
                                value={payDetails.mobile}
                                onChange={(e) =>
                                  handlePayInput("mobile", e.target.value)
                                }
                                isInvalid={!!payErrors.mobile}
                              />
                              <Form.Control.Feedback type="invalid">
                                {payErrors.mobile}
                              </Form.Control.Feedback>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Form.Label>Email</Form.Label>
                            </Col>
                            <Col md={7}>
                              <Form.Control
                                type="email"
                                name="email"
                                value={payDetails.email}
                                onChange={(e) =>
                                  handlePayInput("email", e.target.value)
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <Form.Label>Amount</Form.Label>
                            </Col>
                            <Col md={7}>
                              <Form.Control
                                type="number"
                                value={payDetails.amountToPay}
                                onChange={(e) =>
                                  handlePayInput("amountToPay", e.target.value)
                                }
                                isInvalid={!!payErrors.amountToPay}
                              />
                              <Form.Control.Feedback type="invalid">
                                {payErrors.amountToPay}
                              </Form.Control.Feedback>
                            </Col>
                          </Row>
                          {/* <Row>
                                <Col md={4}>
                                    <label>Alternate Mobile</label>
                                </Col>
                                <Col md={7}>
                                <CustomSwitch handleChange={e=>handlePayCheckBox} name = 'alternate_mobile' value={payDetails.alternate_mobile} />
                                </Col>
                            </Row> */}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      proceedToPayment();
                    }}
                  >
                    Proceed
                  </Button>
                </ModalFooter>
              </Modal>
              {showVoucherForm ? (
                <VoucherForm
                  handleClose={() => setshowVoucherForm(false)}
                  create={issueVoucher}
                />
              ) : (
                ""
              )}
              <Row>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              {handleErr ? (
                <ErrorHandling
                  title="booking"
                  closeErrorHandling={closeErrorHandling}
                  error={errData}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default BookingForm;
