/* eslint-disable import/no-anonymous-default-export */
export default {
  vendor: {
    vendor_name: "Vendor Name",
    address_1: "Address 1",
    address_2: "Address 2",
    category_id: "Category",
    campaign_id: "Campaign",
    type_of_concern_id: "Type of Concern",
    owner_name: "Owner Name",
    owner_phone: "Owner Phone",
    owner_email: "Owner Email",
    contact_email: "Contact Email",
    ac_no: "Account Number",
    ac_holder: "Account Holder",
    ifsc: "IFSC",
    branch_address: "Branch Address",
    mimr: "MICR Code",
    owner_aadhar: "Owner Aadhar",
    owner_PAN: "Owner PAN",
    enrollment_form: "Agreement Form",
    cancelled_cheque: "Cancelled Cheque",
    authorized_sign: "Authorized Sign",
    demo_seen_manager: "Seen Manager",
    demo_seen_manager: "Seen Manager",
    account_confirmation: "Confirm Account",
    is_kyc_pending: "KYC Done",
    is_images_pending: "Images Uploaded",
  },
  mandapam: {
    mandap_name: "Mandap Name",
    mandap_type_id: "Mandap Type",
    mandap_classification_id: "Mandap Classification",
    address_1: "Address 1",
    address_2: "Address 2",
    vendor_id: "Vendor",
    guest: "No. of Guests",
    area_sqft: "Area (sqft)",
    entry_time: "Entry Time",
    exit_time: "Exit Time",
    rate_per_day: "Rate per day",
    city_id: "City",
    state_id: "State",
    on_main_road: "On Main Road",
    residential_area: "Residential Area",
    nearest_Railway_Station: "Nearest Railway Station",
    distance_from_railway_station: "Distance from Railway Station",
    nearest_bus_stand: "Nearest Bus Stand",
    distance_from_bus_stand: "Distance from Bus Stand",
    hall_sq_ft: "Hall (sqft)",
    hall_height: "Hall Height (ft)",
    hall_level: "Hall Level",
    pillarless_hall: "Pillarless Hall",
    seating_capacity: "Seating Capacity",
    lift_access_to_hall: "Lift Access",
    ground_level: "AC Hall",
    stage_available: "Stage Available",
    primary_stage_dim_ft: "Primary Stage Dimension (ft)",
    homam_havan_fireritual: "Homam/Haven Fire Rituals",
    secondary_stage_available: "Secondary Stage Available",
    secondary_stage_dim_ft: "Secondary Stage Dimension (ft)",
    handicap_ramp: "Handicap Ramp",
    dining_capacity: "Dining Capacity",
    dining_level: "Dining Level",
    dining_ac: "AC Dining",
    catering: "Catering",
    outdoor_catering_allowed: "Outdoor Catering Allowed",
    non_veg_cooking_allowed: "Non-veg Cooking allowed",
    non_veg_service_allowed: "Non-veg Service Allowed",
    outdoor_buffet: "Outdoor Buffet Available",
    cooking_fuel_provided: "Cooking Fuel Provided",
    cooking_fuel_used: "Cooking Fuel Used",
    piped_gas: "Piped Gas",
    food_type: "Food Type",
    cooking_utensils_available: "Cooking Utensils Available",
    buffet_crockery_available: "Buffet Crockery Available",
    car_parking_capacity: "Car Parking Capacity",
    bike_parking_capacity: "Bike Parking Capacity",
    valet_parking: "Valet Parking",
    no_of_rooms: "No. of Rooms",
    ac_type: "AC Type",
    no_of_ac_rooms: "No. of AC Rooms",
    no_of_nonac_rooms: "No. of Non-AC Rooms",
    grooms_room: "Groom Rooms",
    bride_room: "Bride Rooms",
    safe_available: "Safe Available",
    wifi: "WIFI",
    common_area_ac: "Common Area AC",
    generator_backup: "Backup Generator",
    serving_available: "Serving Available",
    outside_entertainment_allowed: "Outside Entertainment Available",
    outside_vendor_allowed: "Outside Vendors Allowed",
    other_facilities: "Other Facilities",
  },
  booking: {
    vendor_id: "Vendor",
    mandap_id: "Mandap Name",
    mandap_function_type: "Function Type",
    amount: "Total Amount",
    booking_from: "Booking From",
    booking_to: "Booking To",
    booking_no: "Booking Ref. Number",
    customer_name: "Customer Name",
    mobile: "Mobile",
    email: "Email",
    whatsapp_share: "Share Promotions in WhatsApp",
    address_line_1: "Address Line 1",
    address_line_2: "Address Line 2",
    area: "Area",
    state_id: "State",
    pincode: "Pincode",
  },
  user: {
    first_name: "First Name",
    last_name: "Last Name",
    mobile: "Mobile",
    username: "Username",
    vendor: "Vendor",
    user_role: "Role",
    email: "Email",
  },
  enquiry: {
    mandap_name: "Mandap Name",
    name: "Name",
    mobile: "Mobile",
    email: "Email",
    budget: "Budget",
    date_from: "From Date",
    date_to: "To Date",
    function_type: "Function Type",
    enquiry_source: "Enquiry Source",
    enquiry_status: "Enquiry Status",
    other_status: "Other Statu",
    mandap_id: "Mandap Name",
  },
  viewPayment: {
    charges: "Charges",
  },
  cashback: {
    vendor_id: "Vendor",
    booking_id: "Booking",
    amount: "Amount",
  },
  bonus: {
    vendor_id: "Vendor",
    booking_id: "Booking",
    amount: "Amount",
  },
  target: {
    vendor_id: "Vendor",
    mandap_id: "Mandap",
    user_id: "User",
    month: "Target month",
    year: "Target month",
    target: "Target",
  },
};
