import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import Loader from "../../../Helper/Loader";
import empty from "is-empty";
import Select from "react-select";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import useDebounce from "../../../CustomHooks/useDebounce";

const QRCodeForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [qrcode, setQRCode] = useState([]);
  const [qrcodeList, setQRCodeList] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [vendorList, setvendorList] = useState([]);
  const [selectDropDownString, setSelectDropDownString] = useState("");

  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  let breadcrumbData = [
    {
      title: "Manage",
      href: "/settings",
    },
    {
      title: "Assign QR Code",
      href: "/settings/qrcode",
    },
    {
      title: "Assign",
      href: "#",
    },
  ];
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "QR Code Details",
      id: "details",
      show: true,
    },
  ]);

  const handleSelect = (keyName, setFn, e) => {
    setFn(e);
    setForm({
      ...form,
      [keyName]: (e && e.value) || null,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    history.push("/settings/qrcode");
  };

  const findErrors = () => {
    const { qrcode, vendor } = form;
    const newErrors = {};
    if (empty(qrcode)) newErrors.qrcode = "This is a required field";
    if (empty(vendor)) newErrors.vendor = "This is a required field";

    return newErrors;
  };

  const saveForm = () => {
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let data = { qr_code: qrcode.label, vendor_id: vendor.value },
        postData = new FormData();
      for (let key in data) {
        if (data.hasOwnProperty(key) && data[key] !== null)
          postData.append(key, data[key]);
      }
      axios({
        url: config.service + "/vendor/qrcode/assign-vendor/",
        method: "post",
        headers: tokenKey,
        data: postData,
      })
        .then((res) => {
          if (editMode) setForm(res.data);
          toast.success("Saved successfully", toastAttr);
          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else toast.error("Saving Failed", toastAttr);
          setLoader(false);
        });
    }
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  useEffect(() => {
    get(config.service + "/masters/qrcode/", { headers: tokenKey })
      .then((res) => {
        let qr = [],
          vendor = [];
        res.data.qr_code.map((obj) => {
          // if(empty(obj.vendor_id))
          qr.push({ value: obj.id, label: obj.qr_code });
        });
        res.data.vendor.map((obj) => {
          vendor.push({ value: obj.id, label: obj.vendor_name });
        });
        if (editMode) {
          let selQRcode = propsState && propsState.qrCode;
          let qrVal = qr.filter((temp) => temp.value === selQRcode);
          setQRCode(qrVal);
        }
        setQRCodeList(qr);
        // setvendorList(vendor);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        setLoader(false);
      });
  }, []);

  const handleInputChange = (event) => {
    setSelectDropDownString(event);
  };

  // useDebounce hook for dropDown menu
  const debouncedValue = useDebounce(selectDropDownString, 500);
  const search = useCallback(async () => {
    get(
      config.service + `/masters/base/vendor/?&search=${debouncedValue}`,
      tokenKey
    ).then((res) => {
      let vendors = [];
      res?.data?.results?.map((obj) => {
        vendors.push({ value: obj.id, label: obj.vendor_name });
      });
      setvendorList(vendors);
    });
  }, [debouncedValue]);

  useEffect(() => {
    search();
  }, [debouncedValue, search]);

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv">
                <Col md={12}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              <Row>
                                <Col md={6}>
                                  <Row>
                                    <Col md={4}>
                                      <Form.Label>QR Code</Form.Label>
                                    </Col>
                                    <Col md={7}>
                                      <Select
                                        className={`selectBox ${
                                          !!errors.qrcode ? "inValidField" : ""
                                        }`}
                                        value={qrcode}
                                        onChange={(e) =>
                                          handleSelect("qrcode", setQRCode, e)
                                        }
                                        options={qrcodeList}
                                        isClearable
                                      />
                                      {errors.qrcode ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors.qrcode}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                  {/* {qrcode ? 
                                            <Row>
                                            <Col md={4}>
                                            </Col>
                                            <Col md={7}>
                                            <img src ={} />
                                            </Col>
                                        </Row> : ''} */}
                                  <Row>
                                    <Col md={4}>
                                      <label>Vendor</label>
                                    </Col>
                                    <Col md={7}>
                                      <Select
                                        className={`selectBox ${
                                          !!errors.vendor ? "inValidField" : ""
                                        }`}
                                        value={vendor}
                                        onInputChange={handleInputChange}
                                        onChange={(e) =>
                                          handleSelect("vendor", setVendor, e)
                                        }
                                        options={vendorList}
                                        isClearable
                                      />
                                      {errors.vendor ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors.vendor}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default QRCodeForm;
