import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";

import empty from "is-empty";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";

const VoucherForm = (props) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const findErrors = () => {
    const { voucher_code, expiry_date } = form;
    const newErrors = {};
    if (empty(voucher_code))
      newErrors.voucher_code = "This is a required field";
    if (empty(expiry_date)) newErrors.expiry_date = "This is a required field";

    return newErrors;
  };

  const issueVoucher = () => {
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter the required details to proceed", toastAttr);
    } else {
      form.expiry_date = moment(form.expiry_date).format("YYYY-MM-DD HH:MM:SS");
      props.create(form);
    }
  };

  return (
    <>
      <Dialog open={true} onClose={props.handleClose} maxWidth="md">
        <DialogTitle>Voucher Details</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e) => handleInput("voucher_code", e.target.value)}
            margin="dense"
            required
            id="voucher_code"
            label="Voucher Code"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            onChange={(e) => handleInput("expiry_date", e.target.value)}
            autoFocus
            required
            margin="dense"
            id="expiry_date"
            label="Expiry Date"
            type="date"
            fullWidth
            variant="standard"
            placeholder=" "
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={issueVoucher}>Issue Voucher</Button>
          <Button onClick={props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default VoucherForm;
