import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import empty from 'is-empty'
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'


const Account = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  const [form, setForm] = useState(props.accData);
  const [errors, setErrors] = useState({});

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


   const addAcc = () => {
    let {ac_no,ac_holder,nick_name,ifsc,branch_address} = form
    if(empty(ac_no) || empty(ac_holder) || empty(nick_name) || empty(ifsc) || empty(branch_address))
      toast.error('Enter all the required fields', toastAttr);
    else props.addAccDetails(form)
   }

  return (
    <>
    <Dialog open={true} onClose={props.handleClose} maxWidth='sm'>
        <DialogTitle>Account Details</DialogTitle>
        <DialogContent>
        <TextField onChange={e=> handleInput('ac_no',e.target.value)}
            autoFocus required
            value={form.ac_no}
            margin="dense"
            id="ac_no"
            label="Account Number"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField onChange={e=> handleInput('ac_holder',e.target.value)}
             required 
            value={form.ac_holder}
            margin="dense"
            id="ac_holder"
            label="Account Holder"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField onChange={e=> handleInput('nick_name',e.target.value)}
             required 
            value={form.nick_name}
            margin="dense"
            id="nick_name"
            label="Nick Name"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField onChange={e=> handleInput('ifsc',e.target.value)}
            required
            value={form.ifsc}
            margin="dense"
            id="ifsc"
            label="IFSC"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField onChange={e=> handleInput('mimr',e.target.value)}
            value={form.mimr}
            margin="dense"
            id="mimr"
            label="MICR Code"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField onChange={e=> handleInput('branch_address',e.target.value)}
            value={form.branch_address} required
            margin="dense"
            id="branch_address"
            label="Branch Address"
            type="textarea"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={addAcc}>Add</Button>
          <Button onClick={props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        </>
);
};


export default Account;
