import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import Search from "../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import empty from "is-empty";
import { IoPricetags } from "react-icons/io5";
import { BiRupee } from "react-icons/bi";
import moment from "moment";
import Loader from "../../Helper/Loader";
import PricingDetails from "./PricingDetails";
import ConfirmationModel from "../../../shared/ConfirmationModel";

const Pricing = (props) => {
  const [loading, setLoading] = useState(false);
  const [mandapNotExist, setMandapNotExist] = useState(false);
  const [openNested, setOpenNested] = useState(false);
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let propsState = props.history.location.state;
  let breadcrumbData =
    propsState && propsState?.data
      ? [
          {
            title: "Mandap",
            href: "/vendorForm",
            state: {
              id: propsState?.data.id,
              vendor_id: propsState?.data.vendor_id,
              editMode: true,
              tabValue: "mandapam",
            },
          },
          {
            title: "Pricing",
            href: "#",
          },
        ]
      : [
          {
            title: "Pricing",
            href: "#",
          },
        ];
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let mapPriceType = {
    per_plate: "Price per plate",
    per_day: "Price per Day",
  };
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const cols = [
    {
      dataField: "name",
      text: "Price per day",
      headerStyle: () => {
        return { width: "200px" };
      },
    },
    {
      dataField: "id",
      text: "Actions",
      headerStyle: () => {
        return { width: "120px" };
      },
      events: {
        OnClick: (e) => {
          return e.stopPropagation();
        },
      },
      formatter: (cell, row) => {
        return (
          <div className="tableActions">
            <React.Fragment>
              <Link
                to={{
                  pathname: "/pricingForm",
                  state: { id: row.id, data: propsState?.data },
                }}
              >
                <FiEdit2 title="Edit" />
              </Link>
            </React.Fragment>
          </div>
        );
      },
    },
  ];

  const renderForm = (row) => {
    if (mandapNotExist) {
      toast.error("Mandap does not exist", toastAttr);
      return;
    }
    if (row)
      history.push({
        pathname: "/pricingForm",
        state: {
          id: row.id,
          editMode: true,
          data: propsState?.data,
        },
      });
    else
      history.push({
        pathname: "/pricingForm",
        state: { data: propsState?.data },
      });
  };

  const renderOnSearch = (searchTerm) => {
    get(
      config.service +
        "/masters/mandap_pricing/list/?&mandap_id=" +
        propsState?.data?.id +
        "&search=" +
        searchTerm,
      tokenKey
    )
      .then((res) => {
        settableData(res.data[0]);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        toast.error("Search Failed", toastAttr);
      });
  };

  const deleteMandapPricing = (row) => {
    setLoading(true);
    const priceId = row.id;

    if (!priceId) {
      toast.error("Mandap ID is required.", toastAttr);
      return;
    }

    axios
      .delete(
        config.service + "/masters/mandap_pricing/remove/" + priceId,
        tokenKey
      )
      .then((res) => {
        settableData([]);
        setLoading(false);
        toast.success("Mandap Pricing deleted successfully!", toastAttr); // Optional success message
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 401) {
          window.location.href = "/";
        }
        if (err.response?.data?.mandap_id) {
          const errorMessage = err.response?.data?.mandap_id;
          toast.error(errorMessage, toastAttr); // Show error message as a toast notification
          setMandapNotExist(true); // Handle case where mandap does not exist
        }
      });
  };

  const rowEvents = {
    onClick: (e, row) => {
      history.push({
        pathname: "/pricingForm",
        state: {
          id: row.id,
          editMode: true,
          data: propsState?.data,
        },
      });
    },
  };

  useEffect(() => {
    setLoading(true);

    console.log(propsState?.data, "propsState.data");
    get(
      config.service + "/masters/mandap_pricing/" + propsState?.data.id,
      tokenKey
    )
      .then((res) => {
        settableData(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) window.location.href = "/";
        if (err.response?.data?.mandap_id) {
          const errorMessage = err.response?.data?.mandap_id;
          toast.error(errorMessage, toastAttr);
          setMandapNotExist(true);
        }
      });
  }, []);

  const formatTime = (val) => {
    const d = moment(new Date()).format("L");
    let newVal = moment(d + " " + val).format();
    return moment(newVal).toDate();
  };

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                  {/* <span onClick={proceedToImages}>UPLOAD IMAGES <FaChevronRight /></span> */}
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}>
                  {empty(tableData) ? (
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        renderForm();
                      }}
                    >
                      Create
                    </Button>
                  ) : (
                    <div style={{ display: "flex", alignContent: "center" }}>
                      <Button
                        variant="primary"
                        style={{ display: "flex", alignContent: "center" }}
                        onClick={(e) => renderForm(tableData)}
                      >
                        {/* <IoPricetags className="vendorImg" color="#4AAA4D" />{" "} */}
                        Edit Price
                      </Button>
                      <Button
                        variant="primary"
                        style={{ display: "flex", alignContent: "center" }}
                        onClick={(e) => setOpenNested(true)}
                      >
                        {/* <IoPricetags className="vendorImg" color="#4AAA4D" />{" "} */}
                        Delete Price
                      </Button>
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <Search callbackFn={(e) => renderOnSearch(e)} />
                </Col>
              </Row>
              {loading ? (
                <Loader />
              ) : (
                // <Row className="vendorList pricing">
                //   {!empty(tableData) ? (
                //     <Col md={8} xs={12} className="divCont">
                //       <div
                //         className="child"
                //         onClick={(e) => renderForm(tableData)}
                //         style={{ marginRight: "40px", width:'24px' }}
                //       >
                //         <IoPricetags className="vendorImg" color="#4AAA4D" />
                //       </div>
                //       <div
                //         className="child cont"
                //         onClick={(e) => renderForm(tableData)}
                //         style={{ width: "100%" }}
                //       >
                //         {/* <div className='header'> {mapPriceType[tableData.pricing_type]}</div> */}
                //         <div className="header" style={{ fontSize: "16px" }}>
                //           Price details{" "}
                //         </div>
                //         <div>
                //           <span>Blocking Price : </span>
                //           <BiRupee />
                //           {tableData.reservation_price}
                //         </div>
                //         <br />
                //         <Row>
                //           <Col md={12}>
                //             <Row>
                //               <Col md={6}>
                //                 <div>
                //                   <span>Breakfast / plate - </span>
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span> Veg : </span>
                //                   <BiRupee />
                //                   {tableData.pr_breakfast_vg}
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span>Non-veg : </span>
                //                   <BiRupee />
                //                   {tableData.pr_breakfast_nvg}
                //                 </div>
                //                 <div>
                //                   <span>Lunch / plate - </span>
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span> Veg : </span>
                //                   <BiRupee />
                //                   {tableData.pr_lunch_nvg}
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span>Non-veg : </span>
                //                   <BiRupee />
                //                   {tableData.pr_lunch_vg}
                //                 </div>
                //                 <div>
                //                   <span>Dinner / plate - </span>
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span> Veg : </span>
                //                   <BiRupee />
                //                   {tableData.pr_dinner_vg}
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span>Non-veg : </span>
                //                   <BiRupee />
                //                   {tableData.pr_dinner_nvg}
                //                 </div>
                //               </Col>
                //               <Col md={6}>
                //                 <div>
                //                   <span>FULL DAY - </span>
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span> Price : </span>
                //                   <BiRupee />
                //                   {tableData.fd_price}
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span>Timing : </span>
                //                   {tableData.fd_start_time} -{" "}
                //                   {tableData.fd_end_time}
                //                 </div>
                //                 <div>
                //                   <span>HALF A DAY MORNING - </span>
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span>Price : </span>
                //                   <BiRupee />
                //                   {tableData.hf_am_price}
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span>Timing : </span>
                //                   {tableData.hf_am_start_time} -{" "}
                //                   {tableData.hf_am_end_time}
                //                 </div>
                //                 <div>
                //                   <span>HALF A DAY EVENING - </span>
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span>Price : </span>
                //                   <BiRupee />
                //                   {tableData.hf_pm_price}
                //                 </div>
                //                 <div className="pad-l-20">
                //                   <span>Timing : </span>
                //                   {tableData.hf_pm_start_time} -{" "}
                //                   {tableData.hf_pm_end_time}
                //                 </div>
                //               </Col>
                //             </Row>
                //           </Col>
                //         </Row>
                //       </div>
                //       <div className="child">
                //         {/* <HiPencilAlt className='color-green actionIcon' onClick={e=> renderForm(row)}/> */}
                //       </div>
                //     </Col>
                //   ) : (
                //     <Col md={6} className="pad-20">
                //       No data found
                //     </Col>
                //   )}
                // </Row>
                <PricingDetails tableData={tableData} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmationModel
        operation={"Remove"}
        handleConfirmation={() => {
          setOpenNested(false);
          deleteMandapPricing(tableData);
        }}
        val={openNested}
        modelName={"Pricing"}
        onclose={() => {
          setOpenNested(false);
        }}
      />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default Pricing;
