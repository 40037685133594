import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CircularProgress from "@mui/material/CircularProgress";

function MyVerticallyCenteredModal(props) {
  const { data } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {data?.length > 0 ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {data?.[0]?.mandap_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Field Name</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Mandap Name</th>
                  <td>
                    {data?.[0]?.mandap_name ? data?.[0]?.mandap_name : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Mandap Type</th>
                  <td>
                    {data?.[0]?.mandap_type_name
                      ? data?.[0]?.mandap_type_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Title</th>
                  <td>{data?.[0]?.title ? data?.[0]?.title : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Mandap Classification</th>
                  <td>
                    {data?.[0]?.mandap_classification_name
                      ? data?.[0]?.mandap_classification_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Rate Per Day</th>
                  <td>
                    {data?.[0]?.rate_per_day ? data?.[0]?.rate_per_day : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Address</th>
                  <td>{data?.[0]?.address_1 ? data?.[0]?.address_1 : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Mobile No</th>
                  <td>{data?.[0]?.mobile_no ? data?.[0]?.mobile_no : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Area Name</th>
                  <td>{data[0]?.area_name ? data[0]?.area_name : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Contact Person Name</th>
                  <td>
                    {data[0]?.contact_person_name
                      ? data[0]?.contact_person_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Area Sq.ft</th>
                  <td>{data[0]?.area_sqft ? data[0]?.area_sqft : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">City Name</th>
                  <td>{data?.[0].city_name ? data?.[0].city_name : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Car Parking capacity</th>
                  <td>
                    {data?.[0].car_parking_capacity
                      ? data?.[0].car_parking_capacity
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Bike Parking capacity</th>
                  <td>
                    {data?.[0].bike_parking_capacity
                      ? data?.[0].bike_parking_capacity
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Bride Room</th>
                  <td>
                    {data?.[0].bride_room === null
                      ? "-"
                      : data?.[0].bride_room === true
                      ? "Yes"
                      : data?.[0].bride_room === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Groom Room</th>
                  <td>
                    {data?.[0].grooms_room === null
                      ? "-"
                      : data?.[0].grooms_room === true
                      ? "Yes"
                      : data?.[0].bride_room === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Is Buffet Crockery Available</th>
                  <td>
                    {data?.[0].buffet_crockery_available === null
                      ? "-"
                      : data?.[0].buffet_crockery_available === true
                      ? "Yes"
                      : data?.[0].buffet_crockery_available === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Floating Capacity</th>
                  <td>{data?.[0].guest ? data?.[0].guest : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Seating Capacity</th>
                  <td>
                    {data?.[0]?.seating_capacity
                      ? data?.[0]?.seating_capacity
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Dining capacity</th>
                  <td>
                    {data?.[0].dining_capacity
                      ? data?.[0].dining_capacity
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Catering</th>
                  <td>
                    {data?.[0].catering === null
                      ? "-"
                      : data?.[0].catering === true
                      ? "Yes"
                      : data?.[0].catering === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Food Type</th>
                  <td>{data?.[0]?.food_type ? data?.[0]?.food_type : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Generator Backup</th>
                  <td>
                    {data?.[0].generator_backup === null
                      ? "-"
                      : data?.[0].generator_backup === true
                      ? "Yes"
                      : data?.[0].generator_backup === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Ac</th>
                  <td>
                    {data?.[0].hall_ac === null
                      ? "-"
                      : data?.[0].hall_ac === true
                      ? "Yes"
                      : data?.[0].hall_ac === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Common Area Ac</th>
                  <td>
                    {data?.[0].common_area_ac === null
                      ? "-"
                      : data?.[0].common_area_ac === true
                      ? "Yes"
                      : data?.[0].common_area_ac === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Dining in Ac</th>
                  <td>
                    {data?.[0].dining_ac === null
                      ? "-"
                      : data?.[0].dining_ac === true
                      ? "Yes"
                      : data?.[0].dining_ac === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Dining Level </th>
                  <td>
                    {data?.[0]?.dining_level ? data?.[0]?.dining_level : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Veg only</th>
                  <td>
                    {data?.[0].vegonly === null
                      ? "-"
                      : data?.[0].vegonly === true
                      ? "Yes"
                      : data?.[0].vegonly === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">No of Rooms</th>
                  <td>
                    {data?.[0]?.no_of_rooms ? data?.[0]?.no_of_rooms : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">No of AC Rooms</th>
                  <td>
                    {data[0]?.no_of_ac_rooms ? data[0]?.no_of_ac_rooms : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">No of Non AC Rooms</th>
                  <td>
                    {data?.[0]?.no_of_nonac_rooms
                      ? data?.[0]?.no_of_nonac_rooms
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <th scope="row">Rate per day</th>
                  <td>
                    {data?.[0]?.rate_per_day ? data?.[0]?.rate_per_day : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Seating Capacity</th>
                  <td>
                    {data[0]?.seating_capacity
                      ? data[0]?.seating_capacity
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Half AM Price</th>
                  <td>
                    {data?.[1]?.hf_am_price ? data?.[1]?.hf_am_price : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Half PM Price</th>
                  <td>
                    {data?.[1]?.hf_pm_price ? data?.[1]?.hf_pm_price : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Full Day Price</th>
                  <td>{data?.[1]?.fd_price ? data?.[1]?.fd_price : "-"}</td>
                </tr>
                <tr>
                  <th scope="row">Reservation Price</th>
                  <td>
                    {data?.[0]?.reservation_price
                      ? data?.[0]?.reservation_price
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Cooking Fuel Facility</th>
                  <td>
                    {data?.[0].cooking_fuel_provided === null
                      ? "-"
                      : data?.[0].cooking_fuel_provided === true
                      ? "Yes"
                      : data?.[0].cooking_fuel_provided === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Cooking Utensils Facility</th>
                  <td>
                    {data?.[0].cooking_utensils_available === null
                      ? "-"
                      : data?.[0].cooking_utensils_available === true
                      ? "Yes"
                      : data?.[0].cooking_utensils_available === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Handicap Ramp Facility</th>
                  <td>
                    {data?.[0].handicap_ramp === null
                      ? "-"
                      : data?.[0].handicap_ramp === true
                      ? "Yes"
                      : data?.[0].handicap_ramp === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Having Non Mhurtham Price</th>
                  <td>
                    {data?.[0].have_non_muhurtham_price === null
                      ? "-"
                      : data?.[0].have_non_muhurtham_price === true
                      ? "Yes"
                      : data?.[0].have_non_muhurtham_price === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Having Offer</th>
                  <td>
                    {data?.[0].having_offer === null
                      ? "-"
                      : data?.[0].having_offer === true
                      ? "Yes"
                      : data?.[0].having_offer === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Homam Havan Fireritual Facility</th>
                  <td>
                    {data?.[0].homam_havan_fireritual === null
                      ? "-"
                      : data?.[0].homam_havan_fireritual === true
                      ? "Yes"
                      : data?.[0].homam_havan_fireritual === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Lift Access to Dining Hall</th>
                  <td>
                    {data?.[0].lift_access_to_dining_hall === null
                      ? "-"
                      : data?.[0].lift_access_to_dining_hall === true
                      ? "Yes"
                      : data?.[0].lift_access_to_dining_hall === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Lift Access to Hall</th>
                  <td>
                    {data?.[0].lift_access_to_hall === null
                      ? "-"
                      : data?.[0].lift_access_to_hall === true
                      ? "Yes"
                      : data?.[0].lift_access_to_hall === false
                      ? "No"
                      : null}
                  </td>
                </tr>

                <tr>
                  <th scope="row">Nearest Railway Station</th>
                  <td>
                    {data?.[0].nearest_Railway_Station === null
                      ? "-"
                      : data?.[0].nearest_Railway_Station === true
                      ? "Yes"
                      : data?.[0].nearest_Railway_Station === false
                      ? "No"
                      : null}
                  </td>
                </tr>

                <tr>
                  <th scope="row">Nearest Bus Stand</th>
                  <td>
                    {data?.[0]?.nearest_bus_stand
                      ? data?.[0]?.nearest_bus_stand
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Non Veg Cooking Allowed</th>
                  <td>
                    {
                      data?.[0].non_veg_cooking_allowed === null
                        ? "-"
                        : data?.[0].non_veg_cooking_allowed === true
                        ? "Yes"
                        : data?.[0].non_veg_cooking_allowed === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">Non Veg Service Allowed</th>
                  <td>
                    {
                      data?.[0].non_veg_service_allowed === null
                        ? "-"
                        : data?.[0].non_veg_service_allowed === true
                        ? "Yes"
                        : data?.[0].non_veg_service_allowed === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">On Main Road</th>
                  <td>
                    {
                      data?.[0].on_main_road === null
                        ? "-"
                        : data?.[0].on_main_road === true
                        ? "Yes"
                        : data?.[0].on_main_road === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">Out Door Buffet</th>
                  <td>{data?.[0]?.outdoor_buffet ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th scope="row">Out Door Catering Allowed</th>
                  <td>
                    {
                      data?.[0].outdoor_buffet === null
                        ? "-"
                        : data?.[0].outdoor_buffet === true
                        ? "Yes"
                        : data?.[0].outdoor_buffet === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">Outside Entertainment Allowed</th>
                  <td>
                    {data?.[0].outside_entertainment_allowed === null
                      ? "-"
                      : data?.[0].outside_entertainment_allowed === true
                      ? "Yes"
                      : data?.[0].outside_entertainment_allowed === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Outside vendor Allowed</th>
                  <td>
                    {data?.[0].outside_vendor_allowed === null
                      ? "-"
                      : data?.[0].outside_vendor_allowed === true
                      ? "Yes"
                      : data?.[0].outside_vendor_allowed === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Pillarless Hall</th>
                  <td>
                    {
                      data?.[0].pillarless_hall === null
                        ? "-"
                        : data?.[0].pillarless_hall === true
                        ? "Yes"
                        : data?.[0].pillarless_hall === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">Piped Gas</th>
                  <td>
                    {data?.[0].piped_gas === null
                      ? "-"
                      : data?.[0].piped_gas === true
                      ? "Yes"
                      : data?.[0].piped_gas === false
                      ? "No"
                      : null}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Primary stage Dimension </th>
                  <td>
                    {data?.[0]?.primary_stage_dim_ft
                      ? data?.[0]?.primary_stage_dim_ft
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Safe Avilable</th>
                  <td>
                    {
                      data?.[0].safe_available === null
                        ? "-"
                        : data?.[0].safe_available === true
                        ? "Yes"
                        : data?.[0].safe_available === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>

                <tr>
                  <th scope="row">Secondary Stage Available</th>
                  <td>
                    {
                      data?.[0].secondary_stage_available === null
                        ? "-"
                        : data?.[0].secondary_stage_available === true
                        ? "Yes"
                        : data?.[0].secondary_stage_available === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">Secondary Stage Dimension </th>
                  <td>
                    {data?.[0]?.secondary_stage_dim_ft
                      ? data?.[0]?.secondary_stage_dim_ft
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Serving Avilable</th>
                  <td>
                    {
                      data?.[0].serving_available === null
                        ? "-"
                        : data?.[0].serving_available === true
                        ? "Yes"
                        : data?.[0].serving_available === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">Stage Avilable</th>
                  <td>
                    {
                      data?.[0].stage_available === null
                        ? "-"
                        : data?.[0].stage_available === true
                        ? "Yes"
                        : data?.[0].stage_available === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">Valet Parking</th>
                  <td>
                    {
                      data?.[0].valet_parking === null
                        ? "-"
                        : data?.[0].valet_parking === true
                        ? "Yes"
                        : data?.[0].valet_parking === false
                        ? "No"
                        : null // Adjust the default case based on your requirements
                    }
                  </td>
                </tr>
                <tr>
                  <th scope="row">Wifi Avialble</th>
                  <td>
                    {data?.[0].wifi === null
                      ? "-"
                      : data?.[0].wifi === true
                      ? "Yes"
                      : data?.[0].wifi === false
                      ? "No"
                      : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 30,
          }}
        >
          <CircularProgress style={{ color: "pink" }} />
        </div>
      )}
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
