import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { saveAs } from "file-saver";

import empty from "is-empty";
import Search from "../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField, Popover, Typography } from "@mui/material";
// import {DatePicker} from 'react-datepicker';
import Modal from "react-bootstrap/Modal";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { FaChevronDown } from "react-icons/fa";
import { Box, Slider, Pagination, Dialog, DialogContent } from "@mui/material";

const PaymentStatus = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  // let propsState = props.history.location.state;
  // let params = props.history.location && props.history.location.state && props.history.location.state.
  // params || {};
  // let savedCity1 = city ? city : sessionStorage.getItem('city') || '';
  let category = null;
  let breadcrumbData = [{ title: "Payment Status", href: "#" }];
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  function handleChange(event) {
    const value = event.target.value;
    settrancid(value);
  }
  const [searchResult, setSearchResult] = useState([]);
  const [trancid, settrancid] = useState("");
  const [tableData, settableData] = useState([]);

  const handleSearchSubmit = async (event) => {
    try {
      const response = await axios.get(
        config.service + `/masters/trancid/?trans_id=${trancid}`
      );
      setSearchResult(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchpayments = async () => {
      try {
        const response = await axios.get(config.service + `/masters/trancid/`);
        setSearchResult(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchpayments();
  }, []);

  const fetchModalData = (trancid) => {
    fetch(config.service + `/update_payment/?tracid=${trancid}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        alert(data.message);
        // Handle the JSON data here
      })
      .catch((error) => {
        console.log(error, "data");
      });
  };

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const cols = [
    {
      dataField: "created_on",
      text: "Payment Date",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: (cell, row) => {
        let val = row.created_on
          ? row.created_on.replace("T", " ").replace("Z", "").split(".")[0]
          : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "15px" }}>
            {val.slice(0, 19)}
          </div>
        );
      },
    },
    {
      dataField: "transaction_id",
      text: "Transaction ID",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "booking_id",
      text: "Booking ID",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      // columnWidth: '0px',
      headerStyle: () => {
        return { width: "300px" };
      },
      formatter: (cell, row) => (
        <div style={{ width: "200px", fontSize: "14px" }}>{cell}</div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },
    {
      dataField: "mandap_name",
      text: "Mandap Name",
      headerStyle: () => {
        return { width: "250px" };
      },
      formatter: (cell, row) => (
        <div style={{ width: "400px", fontSize: "14px" }}>{cell}</div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },

    {
      dataField: "payment_status",
      text: "Payment Status",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "booking_payment_status",
      text: "Booking Payment Status",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "booking_status",
      text: "Booking Status",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      text: "Update Payment",
      formatter: (cell, row) => (
        <button
          className="btn btn-info"
          onClick={() => {
            fetchModalData(row.transaction_id);
          }}
        >
          Update
        </button>
      ),
    },
  ];
  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: tableData.length,
  });

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>

              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}></Col>
                <Col md={6}>
                  <Row>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 mt-2">
                      <label htmlFor="name">Transaction ID</label>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-2 col-8">
                      <input
                        value={trancid}
                        type="text"
                        className="form-control color1"
                        name="text"
                        onChange={handleChange}
                        required=""
                      />
                    </div>
                    <Button
                      className="col-lg-2 col-md-2 col-sm-2"
                      variant="primary"
                      onClick={handleSearchSubmit}
                    >
                      Search
                    </Button>
                  </Row>
                </Col>
              </Row>

              <Row className="vendorList">
                {searchResult.length > 0 && (
                  <BootstrapTable
                    bootstrap4
                    wrapperClasses="table-responsive  pad-70 pl-2"
                    data={searchResult}
                    columns={cols}
                    keyField="id"
                    containerStyle={{ width: "100%" }}
                    hover
                    noDataIndication="No data found"
                    pagination={options}
                  />
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentStatus;
