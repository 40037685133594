import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import axios from "axios";


export default function EmbedMap({ latitude, longitude }) {
    const [geocode, setGeocode] = useState({ "latitude": latitude, "longitude": longitude })

    useEffect(() => {
        function getLocation() {
            let bdcApi = 'https://api.bigdatacloud.net/data/reverse-geocode-client'

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log('position.coords.latitude', position.coords.latitude)
                    console.log('position.coords.longitude', position.coords.longitude)
                    bdcApi = `${bdcApi}?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`

                    getApi(bdcApi)
                },
                (err) => {
                    console.error(err)

                    getApi(bdcApi)
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            )
        }
        async function getApi(bdcApi) {
            const apiUrl = bdcApi

            try {
                const response = await axios.get(apiUrl)
                if (response.status === 200) {


                    if ((response?.data?.latitude !== null || undefined) && (response?.data?.longitude !== null || undefined)) {
                        setGeocode({ latitude: response?.data?.latitude, longitude: response?.data?.longitude })
                    }

                }

                return response.data
            } catch (error) {
                console.error('Error reverse geocoding:', error)
                if (geocode?.latitude === null || undefined) setGeocode({ ...geocode, latitude: 13.00707 })
                if (geocode?.longitude === null || undefined) setGeocode({ ...geocode, longitude: 80.26200 })
            }

        }
        if (geocode?.latitude === null && geocode?.longitude === null) getLocation()



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])





    return (<>

        <iframe
            width="300"
            height="290"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            title="embedMap"
            src={`https://maps.google.com/maps?q=${geocode?.latitude},${geocode?.longitude}&hl=es;z=10&amp;&output=embed`}
        >
        </iframe>
    </>)


}
EmbedMap.propTypes = {

    latitude: PropTypes.any.isRequired,
    longitude: PropTypes.any.isRequired
};