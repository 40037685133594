import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody} from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {FiEdit2} from 'react-icons/fi'
import Search from '../../../Helper/CustomSearch'
import axios,{get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from '../../../Helper/Breadcrumbs'
import empty from 'is-empty'


const QRCode = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let propsState = props.history.location.state;
  let breadcrumbData = [
    {
      title : 'Manage',
      href : '/settings'
    },{
      title : 'Assign QR Code',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tableData,settableData] = useState([]);

  const cols = [
    {
        dataField : 'qr_code',
        text:'QR Code',
        headerStyle : () => { return{width : '200px'}}
      },
      {
        dataField : 'vendor_name',
        text:'Vendor Name',
        headerStyle : () => { return{width : '200px'}}
      },
  ];

  const renderForm = (row) => {
      history.push({
        pathname:"/settings/qrcodeForm",
    })
  }

  const renderOnSearch = searchTerm => {
    get(config.service+'/masters/qrcode/?&search='+searchTerm,tokenKey).then(res=>{
        let data = res.data.qr_code;
        data.map(obj => {
            obj.vendor_name = '';
            res.data.vendor.map((row,index)=>{
                if(obj.vendor_id && obj.vendor_id === row.id) obj.vendor_name = row.vendor_name;
            })
        });
      settableData(data);
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/'
      toast.error('Search Failed', toastAttr);
    })
  }

  const rowEvents = {
    onClick : (e,row) =>{
      if(empty(row.vendor_id))
      history.push({
        pathname:"/settings/qrcodeForm",
        state : {
          id: row.id,
        editMode:true,
        qrCode : row.qr_code
        }
    })
    }
  }

  useEffect(() => {
    get(config.service+'/masters/qrcode/',tokenKey).then(res=>{
        let data = res.data.qr_code;
        data.map(obj => {
            obj.vendor_name = '';
            res.data.vendor.map((row,index)=>{
                if(obj.vendor_id && obj.vendor_id === row.id) obj.vendor_name = row.vendor_name;
            })
        });
      settableData(data);
    }).catch(err=>{ 
      if(err.response.status === 401) window.location.href = '/'})
  }, []);
  let options = paginationFactory({
    page : 1,
    totalSize:tableData.length,
    sizePerPage: 20,
    paginationSize:3,
    hidePageListOnlyOnePage : true,
    hideSizePerPage:true,
    // showTotal:true
  })
  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
          <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
    <Button variant='primary' onClick={e=>renderForm()}>Assign</Button>
  </Col>
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row className='vendorList'>
            <BootstrapTable  bootstrap4  wrapperClasses='table-responsive pad-20' data={tableData} columns={cols} 
    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
    pagination={options} />
            <Col md={6}></Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default QRCode;
