import React, { useState, useEffect } from "react";
import Geocode from "react-geocode";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
// import {getGeocode,getLatLng} from "use-places-autocomplete";

//AIzaSyAx9kGOkekI0SGzFPpi6vKk5yRRZWv8YV0
const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBUXjxpjnRkBaXZmvqqMOERqpUpcLi-17c&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // const script = document.createElement('script');
  // script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAx9kGOkekI0SGzFPpi6vKk5yRRZWv8YV0&libraries=places";

  const script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?sensor=false&callback=initMap";
  // script.type = 'text/javascript';
  script.async = true;
  script.defer = true;

  let data =
    props.data && props.data.latitude && props.data.longitude
      ? { lat: Number(props.data.latitude), lng: Number(props.data.longitude) }
      : { lat: 13.067439, lng: 80.237617 };
  const [coord, setcoord] = useState(data);
  const getMapCoords = (ev) => {
    let data = {
      lat: ev.latLng.lat(),
      lng: ev.latLng.lng(),
    };
    setcoord(data);
    props.getCoordinates(data);
  };
  useEffect(() => {
    // Get latitude & longitude from address.
    let geocoder = new window.google.maps.Geocoder();
    if (props.hasAddr && props.data && props.data.address_1)
      geocoder.geocode(
        { address: props.data.address_1 },
        function (results, status) {
          if (status == "OK") {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();
            setcoord({ lat: latitude, lng: longitude });
          } else {
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
  }, [props.hasAddr, props.locData]);

  const getCoord = (pos) => {
    setcoord({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    });
  };

  const handleErr = (err) => {
    console.log(err);
  };

  useEffect(() => {
    // document.body.appendChild(script);
    // if(props.data && props.data.address_1)
    // getGeocode({ address: props.data.address_1 }).then((results) => {
    //   const { lat, lng } = getLatLng(results[0]);
    //   console.log("📍 Coordinates: ", { lat, lng });
    // });
    // function initMap() {
    // if(props.data && props.data.address_1){
    //   var geocoder = new google.maps.Geocoder();
    //   var address = "new york";
    //   geocoder.geocode( { 'address': address}, function(results, status) {
    //     if (status == google.maps.GeocoderStatus.OK) {
    //       var latitude = results[0].geometry.location.lat();
    //       var longitude = results[0].geometry.location.lng();
    //       console.log("📍 Coordinates: ", { latitude, longitude });
    //         } else {
    //           alert('Geocode was not successful for the following reason: ' + status);
    //       }
    //     });
    // }
    // else
    navigator.geolocation.getCurrentPosition(getCoord, handleErr);
    // }

    // navigator.geolocation.getCurrentPosition(function(pos) {
    //     setcoord({
    //       lat : pos.coords.latitude,
    //       lng : pos.coords.longitude
    //     });
    //   });

    return () => {
      // document.body.removeChild(script);
    };
  }, []);
  return (
<>
    <iframe 
  width="300" 
  height="170" 
  frameborder="0" 
  scrolling="no" 
  marginheight="0" 
  marginwidth="0" 
  src="https://maps.google.com/maps?q=11.01035,76.96520&hl=ens&z=17&amp;output=embed"
 >
 </iframe>
 <br />
 <small>
   <a 
    href="https://maps.google.com/maps?q=11.009803131,76.95879800794629&hl=en;z=14&amp;output=embed" 
    target="_blank"
   >
     See map bigger
   </a>
 </small>
    {/* //  <iframe 
    //   width="300" 
    //   height="290" 
    //   frameborder="0" 
    //   scrolling="no" 
    //   marginheight="0" 
    //   marginwidth="0" 
    //    src = "https://maps.google.com/maps?q=11.009803131,76.95879800794629&hl=es;z=10&amp;output=embed"
    //     >
    // </iframe>  */}
    </>
  );
});

export default MyMapComponent;
