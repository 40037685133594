import React from "react";
import Pagination from "@mui/material/Pagination";

export const CustomPagination = (props) => {
  const { totlaCount, getCurrentPageCount } = props;
  return (
    <div>
      <Pagination
        count={totlaCount}
        onChange={(event, value) => getCurrentPageCount(value)}
        color="primary"
      />
    </div>
  );
};
