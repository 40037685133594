import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FaTypo3 } from "react-icons/fa";
import { HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import Search from "../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FiEdit2 } from "react-icons/fi";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import Loader from "../../Helper/Loader";
import { over } from "lodash";
import Pusher from "pusher-js";
import { DropdownButton, Dropdown } from "react-bootstrap";

const Notification = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let propsState = props.location.state;
  let toastAttr = {
    theme: "colored",
    autoClose: 1000,
    position: "top-center",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Notifications",
      href: "#",
    },
  ];
  let mapSource = {
      affiliated: "Affliated",
      online: "Online",
      admin: "Admin",
    },
    mapStatus = {
      awaiting_booking: "Booking Awaiting",
      idea_dropped: "Idea Dropped",
      fixed_mandap: "Mandap Fixed",
    };
  const renderOnSearch = (searchTerm) => {
    get(
      config.service + "/masters/notificationssearch/?&search=" + searchTerm,
      tokenKey
    )
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((err) => {
        console.log("search");
      });
  };

  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const columns = [
    { dataField: "id", text: "ID" },
    {
      dataField: "mandap_name",
      text: "Mandap Name",
      formatter: (cell, row) => (
        <div style={{ width: "300px", fontSize: "14px" }}>{cell}</div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },
    {
      dataField: "from_date",
      text: "From Date format",
      headerStyle: () => {
        return { width: "140px" };
      },
      formatter: (cell, row) => {
        let val = row.from_date
          ? row.from_date.replace("T", " ").replace("Z", "").split(".")[0]
          : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "15px" }}>
            {val.slice(0, 11)}
          </div>
        );
      },
    },
    {
      dataField: "to_date",
      text: "To Date format",
      headerStyle: () => {
        return { width: "140px" };
      },
      formatter: (cell, row) => {
        let val = row.to_date
          ? row.to_date.replace("T", " ").replace("Z", "").split(".")[0]
          : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "15px" }}>
            {val.slice(0, 11)}
          </div>
        );
      },
    },
    {
      dataField: "mandap_function_type",
      text: "Function Type",
      formatter: (cell, row) => (
        <div style={{ width: "300px", fontSize: "14px" }}>{cell}</div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },
    { dataField: "enquiry_type", text: "Type" },
    { dataField: "customer_id", text: "Customer ID" },
    { dataField: "customer_mobile", text: "Customer Mobile" },
    {
      dataField: "customer_mail",
      text: "Customer Mail",
      formatter: (cell, row) => (
        <div style={{ width: "300px", fontSize: "14px" }}>{cell}</div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },
    { dataField: "paid_amount", text: "Paid Amount" },
    {
      dataField: "created_on",
      text: "Placed Date",
      formatter: (cell, row) => {
        let val = row.created_on
          ? row.created_on.replace("T", " ").replace("Z", "").split(".")[0]
          : "";
        return (
          <div style={{ maxWidth: "200px", lineHeight: "15px" }}>
            {val.slice(0, 19)}
          </div>
        );
      },
    },

    // Add any other relevant columns here
  ];

  const handleRowClick = async (row) => {
    history.push({
      pathname: "/NotifyForm",
      state: {
        id: row.id,
        editMode: true,
      },
    });
  };

  const rowEvents = {
    onClick: (e, row) => {
      handleRowClick(row);
    },
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);

  function handleDropdownChange(event) {
    const value = event.target.value;
    setSelectedValue(value);

    // Make the backend API call here with the selected value
    // For example, using the fetch API:
    fetch(config.service + `/masters/notifications/?filter=${value}`, tokenKey)
      .then((response) => response.json())
      .then((data) => {
        // Update the dropdownOptions state with the new options
        setNotifications(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await fetch(
        config.service + "/masters/notifications",
        tokenKey
      );
      const data = await response.json();
      setNotifications(data);
    };

    fetchNotifications();
  }, []);

  const [isMounted, setIsMounted] = useState(true);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: notifications.length,
  });

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={4}>
                  <Search callbackFn={(e) => renderOnSearch(e)} />
                </Col>
                <Col md={4}>
                  <select
                    style={{
                      display: "inline-block",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      padding: "5px 5px",
                      borderRadius: "0px",
                    }}
                    value={selectedValue}
                    onChange={handleDropdownChange}
                  >
                    <option value="unread">Unread</option>
                    <option value="read">Read</option>
                    <option value="all">All</option>
                  </select>
                </Col>
              </Row>
              <Row className="vendorList">
                <BootstrapTable
                  keyField="id"
                  wrapperClasses="table-responsive  pad-70 pl-2"
                  data={notifications}
                  columns={columns}
                  rowEvents={rowEvents}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};
export default Notification;
