import React, {useState,useEffect} from 'react';
import Calendar from 'react-awesome-calendar';
import {sample} from 'lodash'

const ViewCalender = props => {
    const [events, setevents] = useState([]);
    let colors = ['#fd3153','#1ccb9e','#FFB3B3','#9BDDF2','#3694DF', '#D0A2FB'];

    useEffect(() => {
        let data = props.data, listOfEvents=[];
        data.map(obj=>{
            listOfEvents.push({
                id: obj.id,
                color: sample(colors),
                from: obj.booking_from,
                to: obj.booking_to,
                title: obj.function_type
            })
        })
        setevents(listOfEvents)
    }, []);

    return(<Calendar
        events={events}
    />);
}

export default ViewCalender;