import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody, Button } from "reactstrap";
import moment from "moment";
import CustomBackdrop from "../../../../shared/BackDropLoader";
import config from "../../../../config";
import { get } from "axios";
import { CustomGrid } from "../../../../shared/CustomGrid";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import { CustomPagination } from "../../Pagination";

const UserLogs = () => {
  const [getUserLogs, setGetUserLogs] = useState([]);
  const [backDropLoader, setBackGropLoader] = useState(false);
  const [paginationCount, setPageNationCount] = useState(1);

  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };

  useEffect(() => {
    paginationCount > 1 ? setBackGropLoader(true) : setBackGropLoader(false);
    get(
      config.service +
        `/accounts/user/bulk_user_creation_logs/?page=${paginationCount}`,
      { headers: tokenKey }
    )
      .then((response) => {
        setGetUserLogs(response.data);
        setBackGropLoader(false);
      })
      .catch((err) => {
        setBackGropLoader(false);
      });
  }, [paginationCount]);

  // ______________ GET PAGE COUNT _______________//

  const handleGetPageCount = (pageCount) => {
    setPageNationCount(pageCount);
  };

  const columnDefs = [
    {
      headerName: "ID",
      field: "id",
      headerCheckboxSelection: false,
      checkboxSelection: false,
      showDisabledCheckboxes: true,
      filter: true,
      width: "80px",
      resizable: true,
    },
    {
      headerName: "Mobile",
      field: "mobile",
      filter: true,
      resizable: true,
    },
    {
      headerName: "Vendor Name",
      field: "vendor.vendor_name",
      filter: true,
      resizable: true,
    },
    {
      headerName: "Address",
      field: "vendor.address_1",
      resizable: true,
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      filter: true,
    },
    {
      headerName: "Date",
      field: "vendor.created_on",
      filter: true,
      resizable: true,
      cellRenderer: (params) => {
        const date = moment(params?.value, "DD/MM/YYYY HH:mm:ss:SSSSSSSSS");
        // Format the date as YY MM DD
        const formattedDate = date.format("Do , MMM YYYY");

        return formattedDate;
      },
    },
    {
      headerName: "content",
      field: "content",
      filter: true,
      resizable: true,
      cellRenderer: (params) => {
        const content = params.value !== null ? JSON.parse(params.value) : "";
        let val;
        if (content !== "") {
          val = Object.values(content)[0];
        } else {
          val = "-";
        }

        return val;
      },
    },
  ];

  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "UsersLogs",
      href: "#",
    },
  ];

  const handleCloseBackdrop = () => {
    setBackGropLoader(false);
  };

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <Row>
                <Col md="12" lg="12" xl="12" xxl="12">
                  <div className="card__title pageNav">
                    <div className="bold-text">
                      <Breadcrumbs data={breadcrumbData} />
                    </div>
                  </div>
                </Col>
              </Row>
              <div style={{ paddingTop: "10px" }}>
                <CustomGrid
                  rowData={getUserLogs?.results}
                  columnData={columnDefs}
                  tableHeight={470}
                />
              </div>

              <div style={{ paddingTop: "10px" }}>
                <CustomPagination
                  totlaCount={Math.ceil(getUserLogs?.count / 10)}
                  getCurrentPageCount={handleGetPageCount}
                />
              </div>
              <CustomBackdrop
                openBackDrop={backDropLoader}
                closeBackDrop={handleCloseBackdrop}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserLogs;
