import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, CardBody } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import Loader from "../../Helper/Loader";
import empty from "is-empty";
import Select from "react-select";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const NotifyForm = (props) => {
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  const [enquiry, setEnquiry] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (props.location.state && props.location.state.id) {
      const fetchEnquiry = async () => {
        setIsLoading(true);
        try {
          const response = await axios.put(
            config.service +
              `/masters/notifications/${props.location.state.id}/`,
            props.location.state.id,
            tokenKey
          );
          setEnquiry(response.data[0]);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchEnquiry();
    }
  }, [props.location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(
        `/api/enquiries/${enquiry.id}/`,
        enquiry
      );
      setEnquiry(response.data);
      alert("Enquiry updated successfully");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setEnquiry((prevEnquiry) => ({
      ...prevEnquiry,
      [e.target.name]: e.target.value,
    }));
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className="my-5">
      {/* <h3>Edit Enquiry</h3> */}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Form>
          <h4>Notification Details</h4> <br />
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Mandap Name</Form.Label>
              <Form.Control type="text" value={enquiry.mandap_name} readOnly />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Type</Form.Label>
              <Form.Control type="text" value={enquiry.enquiry_type} readOnly />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>From Date</Form.Label>
              <Form.Control type="text" value={enquiry.from_date} readOnly />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>To Date</Form.Label>
              <Form.Control type="text" value={enquiry.to_date} readOnly />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Paid Amount</Form.Label>
              <Form.Control type="text" value={enquiry.paid_amount} readOnly />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Function Type</Form.Label>
              <Form.Control
                type="text"
                value={enquiry.mandap_function_type}
                readOnly
              />
            </Form.Group>
          </Row>
          <h4>Customer Details</h4> <br />
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                value={enquiry.customer_name}
                readOnly
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Customer mobile</Form.Label>
              <Form.Control
                type="text"
                value={enquiry.customer_mobile}
                readOnly
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Customer Mail</Form.Label>
              <Form.Control
                type="text"
                value={enquiry.customer_mail}
                readOnly
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Customer ID</Form.Label>
              <Form.Control type="text" value={enquiry.customer_id} readOnly />
            </Form.Group>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default NotifyForm;
