import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import Loader from "../../../Helper/Loader";
import empty from "is-empty";
import Select from "react-select";
import Breadcrumbs from "../../../Helper/Breadcrumbs";

const BlogPostForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
    "Content-Type": "multipart/form-data",
  };
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [mandap, setMandap] = useState([]);
  const [mandapList, setMandapList] = useState({});
  const [city, setCity] = useState([]);
  const [cityList, setCityList] = useState({});
  const [mandapData, setmandapData] = useState([]);
  const [state, setState] = useState([]);
  const [stateData, setstateData] = useState({});
  const [active, setActive] = useState(false);
  const [file, setFile] = useState();

  const logo = `${process.env.PUBLIC_URL}/img/images/upload.png`;

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "Blog",
      href: "/masters/blogPost",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Blog Details",
      id: "details",
      show: true,
    },
  ]);

  const handleInput = (keyName, value) => {
    if (keyName === "image") {
      setFile(URL.createObjectURL(value));
    }

    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    history.push("/masters/blogPost");
  };

  const findErrors = () => {
    const {
      title,
      short_content,
      content,
      meta_description,
      image,
      meta_keywords,
      meta_title,
      status,
    } = form;
    const newErrors = {};
    if (empty(title)) newErrors.title = "This is a required field";
    if (empty(short_content)) newErrors.content = "This is a required field";
    if (empty(content)) newErrors.content = "This is a required field";
    if (empty(meta_description))
      newErrors.meta_description = "This is a required field";
    if (empty(meta_title)) newErrors.meta_title = "This is a required field";
    if (empty(meta_keywords))
      newErrors.meta_keywords = "This is a required field";
    if (empty(image)) newErrors.image = "This is a required field";
    if (empty(status)) newErrors.image = "This is a required field";

    return newErrors;
  };
  const handleSelect = (keyName, setFn, e) => {
    // if(keyName === 'mandap'){
    //     let selmandap = mandapData.filter(obj => obj.id = e && e.value)
    //     setState(selmandap.state_name);
    // }
    setFn(e);
    setForm({
      ...form,
      [keyName]: (e && e.value) || null,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const saveForm = () => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
        ? config.service + "/masters/blogpost/" + propsState.id + "/"
        : config.service + "/masters/blogpost/";
      // data.area_id = form.id

      let data = new FormData();
      data.append("title", form.title);
      data.append("short_content", form.short_content);
      data.append("content", form.content);
      if (file) data.append("image", form.image);
      data.append("author", form.author);
      data.append("meta_title", form.meta_title);
      data.append("meta_description", form.meta_description);
      data.append("meta_keywords", form.meta_keywords);
      data.append("status", form.status);
      data.append("blog_Link", form.blog_Link);

      axios({
        url: url,
        method: method,
        headers: tokenKey,
        data: data,
      })
        .then((res) => {
          if (editMode) toast.success("Saved successfully", toastAttr);
          renderListView();
          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status[0].status === 401) window.location.href = "/";
          else toast.error("Data already exists", toastAttr);
          setLoader(false);
        });
    }
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  useEffect(() => {
    if (propsState && propsState.editMode)
      get(config.service + "/masters/blogpost/" + propsState.id, {
        headers: tokenKey,
      }).then((resp) => {
        setForm(resp.data[0].data);
        setLoader(false);
      });
    else setLoader(false);

    // if(propsState && propsState.editMode)
    //     get(config.service+'/masters/blogpost/'+propsState.id,{headers:tokenKey}).then(resp=>{
    //       setForm(resp.data[0]);
    //       setLoader(false);
    //     })
    //     else setLoader(false);
  }, []);

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv">
                <Col md={12}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="formBtnsDiv">
                <Col md={12} style={{ margin: "5px 0" }}></Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              <Row>
                                <Col md={12}>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Title *</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        name="title"
                                        value={form.title}
                                        onChange={(e) =>
                                          handleInput("title", e.target.value)
                                        }
                                        isInvalid={!!errors.title}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.title}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Short Content *</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="short_content"
                                        value={form.short_content}
                                        style={{ minHeight: "200px" }}
                                        onChange={(e) =>
                                          handleInput(
                                            "short_content",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.short_content}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.short_content}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Content *</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="content"
                                        value={form.content}
                                        style={{ minHeight: "600px" }}
                                        onChange={(e) =>
                                          handleInput("content", e.target.value)
                                        }
                                        isInvalid={!!errors.content}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.content}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  {/* <Row>
                                                <Col md={2}>
                                                    <Form.Label>Author</Form.Label>
                                                </Col>
                                                <Col md={8}>
                                                  <Form.Control type='text' name="author" value={form.author} onChange={e=>handleInput('author',e.target.value)} isInvalid={!! errors.description}/>
                                                    <Form.Control.Feedback type='invalid'>
                                                      { errors.author }
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row> */}
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Image *</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="file"
                                        name="image"
                                        onChange={(e) =>
                                          handleInput(
                                            "image",
                                            e.target.files[0]
                                          )
                                        }
                                        isInvalid={!!errors.image}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.image}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={2}></Col>
                                    {file ? (
                                      <Col>
                                        <img
                                          src={file}
                                          style={{
                                            maxWidth: "200px",
                                            maxHeight: "100px",
                                          }}
                                          alt="img"
                                        />
                                      </Col>
                                    ) : (
                                      <Col>
                                        <img
                                          src={
                                            form.image
                                              ? config.service.replace(
                                                  "/api",
                                                  ""
                                                ) + form.image
                                              : logo
                                          }
                                          style={{
                                            maxWidth: "200px",
                                            maxHeight: "100px",
                                          }}
                                          alt="img"
                                        />
                                      </Col>
                                    )}
                                  </Row>

                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Meta Title *</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        name="meta_title"
                                        value={form.meta_title}
                                        onChange={(e) =>
                                          handleInput(
                                            "meta_title",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.meta_title}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.meta_title}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>
                                        Meta Description *
                                      </Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="meta_description"
                                        style={{ minHeight: "200px" }}
                                        value={form.meta_description}
                                        onChange={(e) =>
                                          handleInput(
                                            "meta_description",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.meta_description}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.meta_description}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Meta Keywords *</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="meta_keywords"
                                        value={form.meta_keywords}
                                        onChange={(e) =>
                                          handleInput(
                                            "meta_keywords",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.meta_keywords}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.meta_keywords}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>

                                  {/* <Row>
                                                <Col md={4}>
                                                    <Form.Label>State</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                  <Form.Control disabled type='text' name="state" value={state} />
                                                <Select className={`selectBox ${!! errors.state  ? 'inValidField' : ''}`}
                                                    value={state}
                                                    onChange={e=>handleSelect('state',setState,e)}
                                                    options={stateList}
                                                    isClearable
                                                />
                                                </Col>
                                            </Row> */}

                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Blog Link*</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="meta_keywords"
                                        value={form.blog_Link}
                                        onChange={(e) =>
                                          handleInput(
                                            "blog_Link",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.blog_Link}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.blog_Link}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Active *</Form.Label>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        onChange={(e) =>
                                          handleInput(
                                            "status",
                                            e.target.checked
                                          )
                                        }
                                        checked={
                                          form.status === true ? true : false
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.status}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  {/* <Form.Control disabled type='text' name="state" value={state} /> */}
                                  {/* <Select className={`selectBox ${!! errors.state  ? 'inValidField' : ''}`}
                                                    value={state}
                                                    onChange={e=>handleSelect('state',setState,e)}
                                                    options={stateList}
                                                    isClearable
                                                /> */}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default BlogPostForm;
