import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  // bgcolor: "transparent",
  // boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

function ModalforVideoPlayer(props) {
  const { selectedVideo, isplayerClicked, handleCloseVideo } = props;
  //   const [open, setOpen] = React.useState(isplayerClicked);
  //   const handleOpen = () => setOpen(isplayerClicked);
  const handleClose = () => handleCloseVideo();

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={isplayerClicked}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe
            width="460"
            height="280"
            // src={selectedVideo}
            src={selectedVideo}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalforVideoPlayer;
