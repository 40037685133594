import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios, { post, get, patch } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../../config";
import Loader from "../../../Helper/Loader";
import empty from "is-empty";
import Select from "react-select";
import Breadcrumbs from "../../../Helper/Breadcrumbs";

const FaqForm = (props) => {
  let history = useHistory();
  let tokenKey = {
    Authorization: "Token " + sessionStorage.getItem("tokenKey"),
  };
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [mandap, setMandap] = useState([]);
  const [mandapList, setMandapList] = useState({});
  const [city, setCity] = useState([]);
  const [cityList, setCityList] = useState({});
  const [faq, setFaq] = useState([]);
  const [faqType, setFaqType] = useState([
    { value: "city", label: "Home" },
    { value: "city", label: "City" },
    { value: "mandap", label: "Mandap" },
  ]);
  const [mandapData, setmandapData] = useState([]);
  const [state, setState] = useState([]);
  const [stateData, setstateData] = useState({});
  const [active, setActive] = useState(false);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let breadcrumbData = [
    {
      title: "Masters",
      href: "/masters",
    },
    {
      title: "FAQ",
      href: "/masters/faq",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Faq Details",
      id: "details",
      show: true,
    },
  ]);

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    history.push("/masters/faq");
  };

  const findErrors = () => {
    const { question, answer } = form;
    const newErrors = {};
    if (empty(question)) newErrors.question = "This is a required field";
    if (empty(answer)) newErrors.answer = "This is a required field";

    return newErrors;
  };
  const handleSelect = (keyName, setFn, e) => {
    // if(keyName === 'mandap'){
    //     let selmandap = mandapData.filter(obj => obj.id = e && e.value)
    //     setState(selmandap.state_name);
    // }
    setFn(e);
    setForm({
      ...form,
      [keyName]: (e && e.value) || null,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const saveForm = () => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? config.service + "/masters/faq/" + propsState.id + "/"
          : config.service + "/masters/faq/create/",
        data = JSON.parse(JSON.stringify(form));
      // data.area_id = form.id

      axios({
        url: url,
        method: method,
        headers: tokenKey,
        data: data,
      })
        .then((res) => {
          if (editMode) setForm(res.data);
          toast.success("Saved successfully", toastAttr);
          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 401) window.location.href = "/";
          else toast.error("Data already exists", toastAttr);
          setLoader(false);
        });
    }
  };

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  useEffect(() => {
    get(config.service + "/masters/guest/vendor_mandap_all/", {
      headers: tokenKey,
    })
      .then((res) => {
        let mandap = [];
        res.data.map((obj) => {
          mandap.push({ value: obj.id, label: obj.mandap_name });
        });

        if (propsState && propsState.editMode)
          get(config.service + "/masters/faq/?faq_id=" + propsState.id, {
            headers: tokenKey,
          }).then((resp) => {
            let mandapVal = mandap.filter(
              (temp) => temp.value === resp.data[0].mandap_id
            );
            setMandap(mandapVal);
            setForm(resp.data[0]);
            setLoader(false);
          });
        else setLoader(false);
        setMandapList(mandap);
      })
      .catch((err) => {
        setLoader(false);
      });

    get(config.service + "/masters/city/", { headers: tokenKey })
      .then((res) => {
        let city = [];
        res.data.map((obj) => {
          city.push({ value: obj.id, label: obj.name });
        });

        if (propsState && propsState.editMode)
          get(config.service + "/masters/faq/?faq_id=" + propsState.id, {
            headers: tokenKey,
          }).then((resp) => {
            let cityVal = city.filter(
              (temp) => temp.value === resp.data[0].city_id
            );
            let faqVal = faqType.filter(
              (temp) => temp.value === resp.data[0].faq_type
            );
            setCity(cityVal);
            setForm(resp.data[0]);
            setFaq(faqVal);
            setLoader(false);
          });
        else setLoader(false);
        setCityList(city);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv">
                <Col md={12}>
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      saveForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      renderListView();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="formBtnsDiv">
                <Col md={12} style={{ margin: "5px 0" }}></Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) => {
                          let disCls = tab.disable;
                          return tab.show ? (
                            <a
                              className={`nav-item nav-link active`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected="true"
                            >
                              {tab.name}
                            </a>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details ? (
                          <Row>
                            <Col md={12}>
                              <Row>
                                <Col md={12}>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Questions</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="questions"
                                        value={form.question}
                                        onChange={(e) =>
                                          handleInput(
                                            "question",
                                            e.target.value
                                          )
                                        }
                                        isInvalid={!!errors.questions}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.question}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Answers</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="answers"
                                        value={form.answer}
                                        onChange={(e) =>
                                          handleInput("answer", e.target.value)
                                        }
                                        isInvalid={!!errors.description}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.answer}
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>FAQ Type</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Select
                                        className={`selectBox ${
                                          !!errors.mandap ? "inValidField" : ""
                                        }`}
                                        value={faq}
                                        onChange={(e) =>
                                          handleSelect("faq_type", setFaq, e)
                                        }
                                        options={faqType}
                                        isClearable
                                      />
                                      {errors.mandap ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors.mandap}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Mandapam Name</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Select
                                        className={`selectBox ${
                                          !!errors.mandap ? "inValidField" : ""
                                        }`}
                                        value={mandap}
                                        onChange={(e) =>
                                          handleSelect(
                                            "mandap_id",
                                            setMandap,
                                            e
                                          )
                                        }
                                        options={mandapList}
                                        isClearable
                                      />
                                      {errors.mandap ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors.mandap}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>City</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                      <Select
                                        className={`selectBox ${
                                          !!errors.city ? "inValidField" : ""
                                        }`}
                                        value={city}
                                        onChange={(e) =>
                                          handleSelect("city_id", setCity, e)
                                        }
                                        options={cityList}
                                        isClearable
                                      />
                                      {errors.city ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors.city}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>

                                  {/* <Row>
                                                <Col md={4}>
                                                    <Form.Label>State</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                  <Form.Control disabled type='text' name="state" value={state} />
                                                <Select className={`selectBox ${!! errors.state  ? 'inValidField' : ''}`}
                                                    value={state}
                                                    onChange={e=>handleSelect('state',setState,e)}
                                                    options={stateList}
                                                    isClearable
                                                />
                                                </Col>
                                            </Row> */}
                                  <Row>
                                    <Col md={2}>
                                      <Form.Label>Active</Form.Label>
                                    </Col>
                                    <Col md={2}>
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label=""
                                        onChange={(e) =>
                                          handleInput(
                                            "active",
                                            e.target.checked
                                          )
                                        }
                                        checked={
                                          form.active === true ? true : false
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  {/* <Form.Control disabled type='text' name="state" value={state} /> */}
                                  {/* <Select className={`selectBox ${!! errors.state  ? 'inValidField' : ''}`}
                                                    value={state}
                                                    onChange={e=>handleSelect('state',setState,e)}
                                                    options={stateList}
                                                    isClearable
                                                /> */}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default FaqForm;
