import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody,Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {FiEdit2} from 'react-icons/fi'
import Search from '../../Helper/CustomSearch'
import axios,{get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from '../../Helper/Breadcrumbs'
import Loader from '../../Helper/Loader';
import empty from 'is-empty'


const Voucher = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let bookingId = props.location && props.location.state && props.location.state.bookingId;
  let breadcrumbData = bookingId ? props.location.state.breadcrumbData : [
    {
      title : 'Vouchers',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tableData,settableData] = useState([]);
  const [loading, setloading] = useState(true);
  const [overlay, setOverlay] = useState(false);
  const [selectedRows, setselectedRows] = useState([]);
  const [bookingIds, setbookingIds] = useState([]);
  const cols = [
    {
      dataField : 'voucher',
      text:"Voucher",
      headerStyle : () => { return{width : '250px'}}
    },
    {
      dataField : 'booking_no',
      text:"Booking Number",
      headerStyle : () => { return{width : '150px'}}
    },
    {
        dataField : 'vendor_name',
        text:"Vendor",
        headerStyle : () => { return{width : '150px'}}
      },
      {
        dataField : 'mandap_name',
        text:"Mandap Name",
        headerStyle : () => { return{width : '350px'}}
      },
      {
        dataField : 'expiry_date',
        text:"Expiry Date",
        headerStyle : () => { return{width : '120px'}},
        formatter:(cell,row)=>{
          let val = cell ? cell.split('T') : null
          return (<div>{val ? val[0] : null}</div>)
        }
      },
  ];

  const setLoader = (val) => {
    setloading(val);setOverlay(val);
  }


  const renderOnSearch = searchTerm => {
    setLoader(true);
    let url = '/booking/voucher/list/?&search='+searchTerm;
    if(bookingId) url += '&booking_id='+bookingId;
    get(config.service+url,tokenKey).then(res=>{
      settableData(res.data);
      setLoader(false);
    }).catch(err=>{
        setLoader(false);
      if(err.response.status === 401) window.location.href = '/'
      toast.error('Search Failed', toastAttr);
    }) 
  }

  const rowEvents = {
    onClick : (e,row) =>{
      let bdData = bookingId ? breadcrumbData.slice(0,2) : [];
      let label = bookingId ? 'View Vouchers' : 'Vouchers'
       Array.prototype.push.apply(bdData,[
        {
          title : label,
          href : '/voucher',
          state : {
            bookingId : bookingId,
            breadcrumbData : breadcrumbData
          }
        },
        {
          title : 'Details',
          href : '#'
        }
      ])
      history.push({
        pathname:"/voucherForm",
        state : {
          id: row.id,
          editMode:true,
          breadcrumbData : bdData,
          bookingId : bookingId
        }
    })
    }
  }

  const redeemVoucher = () =>{
    setLoader(true);
    let data ={
      'ids':  selectedRows
    }

    patch(config.service+'/booking/voucher/update/',data,{headers:tokenKey}).then(res=>{
      setselectedRows([]); setbookingIds([]);
      setLoader(false);
      toast.success('Selected Vouchers are marked as redeemed', toastAttr);
    }).catch(err=>{
      setselectedRows([]);setbookingIds([]);
      setLoader(false);
      if(err.response.status === 401) window.location.href = '/';
      toast.error('Voucher redeem failed', toastAttr);
    })
  }

  const checkRow = (row,isSelected) =>{
    if(isSelected) {
      let val = JSON.parse(JSON.stringify(selectedRows)) , temp = JSON.parse(JSON.stringify(selectedRows));
      val.push(row.id); temp.push(row.booking_id);
      setselectedRows(val); setbookingIds(temp);
    }
    else {
      let val = selectedRows.filter(obj=>obj !== row.id);
      let temp = bookingIds.filter(obj=>obj !== row.booking_id);
      setselectedRows(val);setbookingIds(temp);
    }
  }

  const checkAllRows = (isSelected, row) =>{
    if(isSelected){
      let rowIds = [], bookIds = [];
      row.map(obj=> {rowIds.push(obj.id); bookIds.push(obj.booking_id)});
      setselectedRows(rowIds); setbookingIds(bookIds);
    } else {setselectedRows([]); setbookingIds([])}
  }

  useEffect(() => {
    let url = '/booking/voucher/list/';
    if(bookingId) url += '?&booking_id='+bookingId;
    get(config.service+url,tokenKey).then(res=>{
      settableData(res.data);
      setLoader(false);
    }).catch(err=>{ 
        setLoader(false);
      if(err.response.status === 401) window.location.href = '/'})
  }, []);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:tableData.length
  })

  const selectRow = {
    mode : 'checkbox',
    clickToSelect : false,
    onSelect : checkRow,
    onSelectAll : checkAllRows,
    selected : selectedRows,
    selectColumnStyle : {textOverflow : 'unset'}
  }

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
        <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
  <Button variant='primary' disabled={empty(selectedRows)} onClick={e=>redeemVoucher()}>Redeem Voucher</Button>
  </Col>
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row className='vendorList'>
        <Loader loading={loading} overlay={overlay}>
            <BootstrapTable  bootstrap4  wrapperClasses='table-responsive  pad-20' data={tableData} columns={cols} 
    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
    pagination={options} selectRow={ selectRow } />
    </Loader>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default Voucher;