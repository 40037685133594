import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Col, Container, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import fs from "fs";
import moment from "moment";

const SignUpLeads = () => {
  let toastAttr = {
    theme: "colored",
    position: "top-left",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const cols = [
    {
      dataField: "name",
      text: "Name",
      headerStyle: () => {
        return { width: "100px" };
      },
      formatter: (cell) => {
        return (
          <>
            <p style={{ lineHeight: "normal", whiteSpace: "nowrap" }}>
              {cell ? cell : "-"}
            </p>
          </>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "mobile",
      text: "Mobile",
      headerStyle: () => {
        return { width: "100px" };
      },
      formatter: (cell) => {
        return (
          <>
            <p style={{ lineHeight: "normal", whiteSpace: "nowrap" }}>{cell}</p>
          </>
        );
      },
    },
    {
      dataField: "Customer",
      text: "Customer",
      headerStyle: () => {
        return { width: "100px" };
      },
      formatter: (cell) => {
        return (
          <>
            <p style={{ lineHeight: "normal", whiteSpace: "nowrap" }}>{cell}</p>
          </>
        );
      },
    },
    {
      dataField: "otp_code",
      text: "OTP Code",
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "verified",
      text: "Verified",
      headerStyle: () => {
        return { width: "100px" };
      },
      formatter: (cell) => {
        return (
          <>
            <p style={{ lineHeight: "normal" }}>{cell ? "Yes" : "No"}</p>
          </>
        );
      },
    },
    {
      dataField: "created_on",
      text: "created_on",
      headerStyle: () => {
        return { width: "100px" };
      },
      formatter: (cell) => {
        return (
          <>
            <p style={{ lineHeight: "normal" }}>
              {moment(cell, "DD/MM/YYYY HH:mm:ssSSSS").format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </p>
          </>
        );
      },
    },
  ];

  const [leadsData, setleadsData] = useState([]);
  const [isdownloading, setDownloading] = useState(false);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: leadsData.length,
  });

  const DownloadData = async () => {
    setDownloading(true);
    const headers = {
      "Content-Type": "blob",
      Authorization: "Token " + sessionStorage.getItem("tokenKey"),
    };
    const configd = {
      method: "GET",
      url: `${config.service}/sms/signUp_logs_download/`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(configd);

      const outputFilename = `${Date.now()}.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      toast.success("file downloaded", toastAttr);

      // OR you can save/write file locally.
      fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      console.error(error);
      //   toast.error("Error downloading the file", toastAttr);
      //   throw Error(error);
    }
    // try {
    //   const response = await axios.get(
    //     `${config.service}/masters/contact_us/download`,
    //     {
    //       responseType: "blob", // Important for downloading files
    //     }
    //   );
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", "data.csv");
    //   document.body.appendChild(link);
    //   link.click();
    //   link.parentNode.removeChild(link);
    //   toast.success("file downloaded", toastAttr);
    //   setDownloading(false);
    // } catch (error) {
    //   setDownloading(false);
    //   toast.error("Error downloading the file", toastAttr);
    // }
  };

  useEffect(() => {
    axios
      .get(config.service + `/sms/signUp_logs/`, {
        responseType: "json",
        headers: {
          Authorization: "Token " + sessionStorage.getItem("tokenKey"),
        },
      })
      .then((res) => {
        console.log(res, "inquiry");
        setleadsData(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            {leadsData?.length > 0 && (
              <div className="d-flex justify-content-end">
                <Button
                  className="mr-4 btn-success text-white"
                  style={{ cursor: "pointer" }}
                  onClick={() => DownloadData()}
                >
                  {isdownloading ? "Downloading" : "Download"}
                </Button>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            {leadsData?.length > 0 ? (
              <div style={{ paddingBottom: "15px" }}>
                <BootstrapTable
                  bootstrap4
                  wrapperClasses="table-responsive pad-20"
                  data={leadsData}
                  columns={cols}
                  keyField="id"
                  containerStyle={{ width: "100%" }}
                  hover
                  noDataIndication="No data found"
                  pagination={options}
                />
              </div>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "80vh",
                }}
              >
                <CircularProgress style={{ color: "rgb(240, 68, 140)" }} />
              </Box>
            )}
          </Col>
        </Row>
      </Container>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SignUpLeads;
