import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FormControl, FormControlLabel, RadioGroup,Radio, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'
import { Button } from 'react-bootstrap';
import {get,post} from 'axios'
import config from '../../../config'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import {filter,includes} from 'lodash'
import empty from 'is-empty'
import {FaSquare} from 'react-icons/fa'

const MarkDates = (props) => {
    let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
    
  const [value, setvalue] = useState(null)
  const [selDate, setselDate] = useState([])
  const [showOptions, setshowOptions] = useState(false);
  const [optionVal,setoptionVal] = useState('muhurtham');
  const [check, setcheck] = useState(0)
  const [resData, setresData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [highlightArr, sethighlightArr] = useState([ {"react-datepicker-day-full": []},{"react-datepicker-day-selected": []}]);
  const [exclude, setexclude] = useState([]);
  const [initOption,setinitOption] = useState({});


      useEffect(() => {
        setDefaultDates(props.data)
      }, []);

      const setDefaultDates = resData => {
        let muhurtham = [];
        resData = resData.filter(val=> val.is_muhurtham === true)
        resData.map(obj=>{
            muhurtham.push(new Date(obj.date))
        })
        let highlight = [
          {"react-datepicker-day-full": muhurtham},
          {'react-datepicker-day-selected' : []}
        ];
        sethighlightArr(highlight);
        setcheck(check+1)
        setresData(resData);
      }

      const handleChange = (newDate) => {
        let full = highlightArr[0]['react-datepicker-day-full'], selected = highlightArr[1]['react-datepicker-day-selected'],options = 'muhurtham';
        newDate.setHours(0,0,0,0); 
        let isFull = full.find(date =>date.toDateString() === newDate.toDateString());
        if(isFull) options='muhurtham';
        setStartDate(newDate);
        setoptionVal(options)
        setcheck(check+1)
        setinitOption(options);
        let alreadySelected = selected.find(date =>date.toDateString() === newDate.toDateString());
        if(alreadySelected) 
          selected = selected.filter(val => val.toDateString() !== newDate.toDateString());
        else selected.push(newDate); 
        let highlight = [
          {"react-datepicker-day-full": full},
          {'react-datepicker-day-selected' : selected}
        ];
        sethighlightArr(highlight);
        props.saveDates(selected);
        // setshowOptions(true)
      }

      const proceed = () => {
        let dates = selDate,formattedDate = moment(startDate).format('YYYY-MM-DD'),
            full = highlightArr[0]['react-datepicker-day-full'];

        full = full.filter(val => val.toDateString() !== startDate.toDateString());
        dates.map((obj,index)=>{
            if(obj.date === formattedDate)  dates.splice(index,1);
          })
        dates.push({
            is_muhurtham: optionVal === 'muhurtham',
            date : formattedDate,
         })
         if(optionVal === 'muhurtham') full.push(startDate)

        let highlight = [
          {"react-datepicker-day-full": full},
        ];
        setselDate(dates);
        sethighlightArr(highlight)
        setcheck(check+1);
        if(!empty(selDate)) props.saveDates(selDate)
        handleOptionClose();
      }

      const handleOptionClose = () => {
        setoptionVal(null);
        setshowOptions(false)
      }

      return (
        <Row >
            <Col md={12}>
                <Row>
                    <Col md={7} className='date-modal'>
                        <DatePicker allowSameDay={true}
                                open={true}
                                showNow={false}
                            selected={startDate}
                            onChange={handleChange}
                            highlightDates={highlightArr}
                            placeholderText=""
                            // excludeDates = {exclude}
                            //   onSelect={handleSelect}
                            shouldCloseOnSelect={false}
                            monthsShown={1}
                            minDate={new Date()}
                            />
                    </Col>
                    <Col md={4} className='legends-update'>
                        <div><FaSquare color='#00D76D' /> Muhurtham days</div>
                        <div><FaSquare color='#9DD5E6' /> Selected days</div>
                        {/* <div><FaSquare color='#9BE079' /> Evening half blocked</div> */}
                    </Col>
                </Row>
                {showOptions ? 
                    <Dialog open={showOptions} onClose={()=>setshowOptions(false)} maxWidth='md'>
                    <DialogTitle>Mark it as  </DialogTitle>
                    <DialogContent className='slotCont1'>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={optionVal}
                                onChange={e=>setoptionVal(e.target.value)}
                            >
                                <FormControlLabel value="muhurtham" control={<Radio />} label="Muhurtham" />
                                <FormControlLabel value="non-muhurtham" control={<Radio />} label="Non-Muhurtham" />
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={proceed}>Update</Button>
                    <Button onClick={handleOptionClose}>Cancel</Button>
                    </DialogActions>
                </Dialog> : ''}
                <span style={{display:'none'}}>{check}</span>
            </Col>
            <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        </Row>

      );
    
}
export default MarkDates;