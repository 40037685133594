import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import Loader from '../../Helper/Loader'
import empty from 'is-empty'
import Topbar from '../../Layout/topbar/Topbar';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const ResetPassword = ({mobileSidebarVisibility,sidebarVisibility}) => {
  let history = useHistory();
  let {token} = useParams();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false) 
  const [loading, setloading] = useState(false);
  const [overlay, setoverlay] = useState(false);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tab, setTab] = useState({
      details:true,
  });
    const [tabData, setTabData] = useState([
        {
            name:'Details',
            id:'details',
            show:true
        }
    ]);

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


  const redirectToLogin = () => {
      history.push("/")
  }

  const findErrors = () => {
    const { password } = form
    const newErrors = {}
    if ( empty(password) ) newErrors.password = 'This is a required field';

    return newErrors;
   }

  const saveForm = () => {
    const newErrors = findErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors)
      toast.error('Enter the required details to proceed', toastAttr);
    } else {
      setLoader(true);
        let data=JSON.parse(JSON.stringify(form));
        axios({
            url: config.service+'/accounts/user/reset-password-verify/',
            method: 'post',
            data: data,
            headers : {Authorization:'Token '+token}
          }).then(res=>{
              setLoader(false);
              toast.success('Password Reset success', toastAttr);
              setTimeout(() =>  window.location.href = "/adminportal", 1000);
        }).catch(err=>{
          if(err.response.status === 401) window.location.href = "/adminportal"
          else
            toast.error('Password Reset Failed', toastAttr);
            setLoader(false);
        })
    }
  }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }


const layoutClass = classNames({
    layout: true,
    'layout--collapse': true,
  });

  return (
      <>
    <div className={layoutClass}>
    <Topbar hideLogout={true}
      changeMobileSidebarVisibility={mobileSidebarVisibility}
      changeSidebarVisibility={sidebarVisibility}
    />
    </div>
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
          <div className="bold-text themeColor pad-l-10">Reset Password</div>
        </div>
        <Row className='formBtnsDiv'>
            <Col md={12}>
                    <Button variant='primary' onClick={e=>{saveForm()}}>Reset</Button> 
                    <Button variant='secondary' onClick={e=>{redirectToLogin()}}>Cancel</Button>
            </Col>
        </Row>
        <Row className='formBtnsDiv'>
            <Col md={12} style={{margin:'5px 0'}}>
            </Col>
        </Row>
        <Row className='formDiv'>
            <Col md={12}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                            {tabData.map((tab,index)=>{
                                let disCls = tab.disable;
                                return tab.show ? (
                                    <a className={`nav-item nav-link active`} 
                                    id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'>
                                        {tab.name}
                                    </a>
                                ) : '';
                            })}
                        </div>
                    </div>
                </nav>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                    <Loader loading={loading} overlay={overlay}>
                        {tab.details ? 
                        <Row><Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>New Password</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='password' name="password" value={form.password} onChange={e=> handleInput('password',e.target.value)} isInvalid={!! errors.password} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.password }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                        </Col></Row>
                        : ''}
                    </Loader>
                    </Form>
                </div>
            </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container></>
);
};

ResetPassword.propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

export default ResetPassword;
