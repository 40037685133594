import React from 'react';

const Loader = ({
    type='spinner-border',
    loading = true,
    children = null,
    overlay=false,
    text=true
}) => {
    let divClass = overlay && children ? 'overlayDiv' : 'loaderDiv';
    return loading ? (
        <div className='loaderCont' style={{zIndex:'9999'}}>
            <div className={divClass}>
                <div className={type} style={{color:'#DC141E', position:'absolute',top:'220px'}} />
                {text ? <div className='loadingComp'>Loading...</div>: ''}
            </div>
            {overlay && children ? children : null}
        </div>
    ) : (children);
};

export default Loader;