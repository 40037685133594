import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import Loader from '../../Helper/Loader'
import empty from 'is-empty'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'


const ResetPasswordForm = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  const [form, setForm] = useState({username : props.username || ''});
  const [errors, setErrors] = useState({});

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


  const redirectToLogin = () => {
      history.push("/")
  }

  const findErrors = () => {
    const { username,email } = form
    const newErrors = {}
    if ( empty(username) ) newErrors.username = 'This is a required field';
    if ( empty(email) ) newErrors.email = 'This is a required field';

    return newErrors;
   }

  const resetPwd = () => {
    const newErrors = findErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors)
      toast.error('Enter the required details to proceed', toastAttr);
    } else {
      let data = {
        email : form.email,
        username : form.username
    }
      post(config.service+'/accounts/user/reset-password/',data,{headers:tokenKey}).then(res=>{
        toast.success('Reset password link sent to the email', toastAttr);
      }).catch(err=>{
          if(err.response.status === 401) window.location.href = '/'
          toast.error('Password reset failed', toastAttr);
        });
        props.handleClose()
      }
    }


const layoutClass = classNames({
    layout: true,
    'layout--collapse': true,
  });

  return (
    <>
    <Dialog open={true} onClose={props.handleClose} maxWidth='md'>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
        <TextField onChange={e=> handleInput('username',e.target.value)}
            autoFocus required
            value={form.username}
            margin="dense"
            id="username"
            label="User name"
            type="text"
            fullWidth
            variant="standard"
          />
          {/* <TextField onChange={e=> handleInput('email',e.target.value)}
            autoFocus required
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}
          <select onChange={e=> handleInput('email',e.target.value)} style={{width:"100%",border:"none",outline:"none",marginTop:"15px",paddingTop:"10px"}}>
            <option value="">Choose the User Mail *</option>
            <option value="info@bookwedgo.com">Admin</option>
            <option value="muthukumaran.perumal@cirantait.com">Developer</option>
          </select>
          <hr style={{borderTop:"1px solid grey",marginTop:"3px"}}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetPwd}>Send Reset Link</Button>
          <Button onClick={props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        </>
);
};


export default ResetPasswordForm;
