import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config';
import Loader from '../../../Helper/Loader'
import empty from 'is-empty'
import Select from 'react-select'
import Breadcrumbs from '../../../Helper/Breadcrumbs'
import CustomSwitch from "../../../Helper/CustomSwitch";


const PaymentLinkForm = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  let breadcrumbData = [
    {
      title : 'Payment',
      href : '/payment'
    },{
      title : 'Links',
      href : '/payment/links'
    },{
      title : 'View',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tab, setTab] = useState({
      details:true,
  });
    const [tabData, setTabData] = useState([
        {
            name:'Payment Link Details',
            id:'details',
            show:true
        }
    ]);

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }
    const handleSelect = (keyName,setFn,e) =>{
        setFn(e);
        setForm({
            ...form,
            [keyName] : (e && e.value) || null
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


  const findErrors = () => {
    const { name } = form
    const newErrors = {}
    if ( empty(name) ) newErrors.name = 'This is a required field';

    return newErrors;
   }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }


  useEffect(() => {
    if(propsState && propsState.editMode)
        get(config.service+'/paymentlinklog/'+propsState.id+'/',{headers:tokenKey}).then(res=>{
            setForm(res.data);
            setLoader(false);
        }).catch(err=>{
          setLoader(false);
        })
        setLoader(false);
  }, []);


  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
        <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
                  </div>
        <Row className='formDiv'>
            <Col md={12}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                            {tabData.map((tab,index)=>{
                                let disCls = tab.disable;
                                return tab.show ? (
                                    <a className={`nav-item nav-link active`} 
                                    id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'>
                                        {tab.name}
                                    </a>
                                ) : '';
                            })}
                        </div>
                    </div>
                </nav>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                    <Loader loading={loading} overlay={overlay}>
                        {tab.details ? 
                        <Row><Col md={12}>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Mobile</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control disabled type='text' value={form.mobile} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Email</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control disabled type='email' value={form.email} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Payment Type</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control disabled type='text'  value={form.payment_type} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Amount</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control disabled type='text'  value={form.amount} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Created Date</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control disabled type='text'  value={form.created_on && form.created_on.replace('T',' ').replace('Z','').split('.')[0]} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Updated Date</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control disabled type='text'  value={form.updated_on && form.updated_on.replace('T',' ').replace('Z','').split('.')[0]} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                        <Row>
                                                <Col md={4}>
                                                    <Form.Label>Is Registered Mobile</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <CustomSwitch disabled name = 'is_registered_mobile' value={form.is_registered_mobile} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>URL</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control as='textarea' disabled  value={form.url} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Encrypt Params</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control disabled type='text'  value={form.encrypt_params} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Active</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <CustomSwitch disabled name = 'active' value={form.active} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Hidden</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <CustomSwitch disabled name = 'active' value={form.hidden} />
                                                </Col>
                                            </Row>
                                            {form.hidden ?
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Hidden Reason</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control as='textarea' disabled  value={form.hidden_reason} />
                                                </Col>
                                            </Row> : ''}
                                        </Col>
                                    </Row>
                        </Col></Row>
                        : ''}
                    </Loader>
                    </Form>
                </div>
            </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default PaymentLinkForm;
