import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import Loader from '../../Helper/Loader'
import empty from 'is-empty'
import Select from 'react-select'
import Breadcrumbs from '../../Helper/Breadcrumbs'

const icon = `${process.env.PUBLIC_URL}/img/logo_1.ico`;
const bg = `${process.env.PUBLIC_URL}/img/images/voucher.jpeg`;


const VoucherForm = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  let breadcrumbData = props.location.state.breadcrumbData
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }


  useEffect(() => {
    if(propsState && propsState.editMode)
        get(config.service+'/booking/voucher/'+propsState.id+'/',{headers:tokenKey}).then(res=>{
            setForm(res.data);
            setLoader(false);
        }).catch(err=>{
          setLoader(false);
        })
        setLoader(false);
  }, []);

  const redeemVoucher = () =>{
    setLoader(true);
    let data ={
      'ids':  [form.id]
    }

    patch(config.service+'/booking/voucher/update/',data,{headers:tokenKey}).then(res=>{
      get(config.service+'/booking/voucher/'+propsState.id+'/',{headers:tokenKey}).then(res=>{
        setForm(res.data);
    })
      setLoader(false);
      toast.success('Voucher redeemed', toastAttr);
    }).catch(err=>{
      setLoader(false);
      if(err.response.status === 401) window.location.href = '/';
      toast.error('Voucher redeem failed', toastAttr);

    })
  }


  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
        <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
                  </div>
                  {form.status && form.status !== 'redeemed' ?
                  <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
  <Button variant='primary' onClick={e=>redeemVoucher()}>Redeem Voucher</Button>
  </Col>
        </Row> : ''}
        <Row className='formDiv'>
            <Col md={12}>
            <Loader loading={loading} overlay={overlay}>
                <Row>
                  <Col md={12} className='voucher'>
                    {/* <div className='voucherContent'>
                          <div className='header'>
                            <img src={icon} alt="logo"  />
                            <span>{form.voucher}</span>
                          </div>
                          <div className='bodyContent'>
                            <div className='cont'>Gift Voucher</div>
                            <div className='img-bg'><img src={bg} alt="bg"  /></div>
                            <div className='footer'>Contact info@bookwedgo.com for futher queries.</div></div>
                    </div> */}
                    <div className='voucherContent' style={{ backgroundImage: `url(${bg})` }}>
                    {/* <img src={bg} alt="voucher"  /> */}
                    <img src={icon} className='logo' alt="logo"  />
                    <div className='vouchernum'>{form.voucher}</div>
                    <div className='contact'>Contact info@bookwedgo.com for futher queries.</div>
                    </div>
                  </Col>
                </Row>
                </Loader>
            </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default VoucherForm;
