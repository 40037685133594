import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody,Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { GoLinkExternal } from 'react-icons/go';
import { IoLogoBuffer } from 'react-icons/io';
import {FaMoneyCheck} from 'react-icons/fa';
import { CiMoneyCheck1 } from 'react-icons/ci';

const Payments = () => {
  let history = useHistory();

  const redirectTo = path => {
    history.push('/payment/'+path)
  }

 return(
    <Container className="masters">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
      <div className="card__title pageNav">
          <div className="bold-text themeColor pad-l-10">Payments</div>
        </div>
        <Row style={{padding : '0 20px 10px 0'}}>
          <Col md={12}>
            <Row>
            <Col md={2} className='m_col'>
              <div className='m_icon'><IoLogoBuffer style={{color: 'limegreen'}} onClick={e=>redirectTo('logs')} /><div>Payment Logs</div></div>
              </Col>
              <Col md={2} className='m_col'>
                <div className='m_icon'><GoLinkExternal style={{color : 'blue'}} onClick={e=>redirectTo('links')} /><div>Payment Links</div></div>
              </Col>
              <Col md={2} className='m_col'>
                <div className='m_icon'><FaMoneyCheck style={{color: '#F1A1C6'}} onClick={()=>history.push('/viewPayment')} /><div>View Payments</div></div>
              </Col>
              <Col md={2} className='m_col'>
                <div className='m_icon'><CiMoneyCheck1 style={{color: '#F1A1C6'}} onClick={()=>history.push('/paymentResponse')} /><div>View Payment Responses</div></div>
              </Col>
            </Row>
            
          </Col>
        </Row>
          </CardBody>
          </Card>
          </Col>
          </Row>
          </Container>
 )
}

export default Payments;